"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNonNegative = void 0;
/** Validator that only allows non-negative numbers (`>= 0`). */
const isNonNegative = (value) => {
    if (typeof value !== 'number' || value < 0 || Number.isNaN(value)) {
        return 'value should be a non-negative number';
    }
    return undefined;
};
exports.isNonNegative = isNonNegative;
