var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "apos-input-wrapper", class: _vm.wrapperClasses },
    [
      _c(
        "select",
        {
          staticClass: "apos-input apos-input--select",
          class: _vm.classes,
          attrs: {
            uid: _vm.uid,
            disabled: _vm.disabled,
            autocomplete: _vm.autocomplete,
          },
          on: {
            change: function ($event) {
              return _vm.change($event.target.value)
            },
          },
        },
        _vm._l(_vm.choices, function (choice) {
          return _c(
            "option",
            {
              key: JSON.stringify(choice.value),
              domProps: {
                value: JSON.stringify(choice.value),
                selected: choice.value === _vm.selected,
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t(choice.label)) + "\n    ")]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("AposIndicator", {
        staticClass: "apos-input-icon",
        attrs: { icon: "menu-down-icon", "icon-size": 20 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }