var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AposContextMenu", {
    staticClass: "apos-admin-bar_context-button",
    attrs: {
      menu: _vm.menu,
      disabled: _vm.disabled || _vm.menu.length === 0,
      "menu-placement": "bottom-end",
      button: {
        tooltip: { content: "apostrophe:moreOptions", placement: "bottom" },
        label: "apostrophe:moreOptions",
        icon: "dots-vertical-icon",
        iconOnly: true,
        type: "subtle",
        modifiers: ["small", "no-motion"],
      },
    },
    on: {
      "item-clicked": _vm.menuHandler,
      open: function ($event) {
        return _vm.$emit("menu-open")
      },
      close: function ($event) {
        return _vm.$emit("menu-close")
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }