var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "apos-tiptap-select" },
    [
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.current,
              expression: "current",
            },
          ],
          staticClass: "apos-tiptap-control apos-tiptap-control--select",
          on: {
            change: [
              function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.current = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
              _vm.takeAction,
            ],
          },
        },
        _vm._l(_vm.actions, function (action) {
          return _c(
            "option",
            { key: action.name, domProps: { value: action.name } },
            [_vm._v("\n      " + _vm._s(_vm.$t(action.label)) + "\n    ")]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("chevron-down-icon", {
        staticClass: "apos-tiptap-select__icon",
        attrs: { size: 11, "fill-color": "currentColor" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }