var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AposModal",
    _vm._g(
      {
        staticClass: "apos-confirm",
        attrs: { modal: _vm.modal },
        on: {
          "no-modal": function ($event) {
            return _vm.$emit("safe-close")
          },
          inactive: function ($event) {
            _vm.modal.active = false
          },
          "show-modal": function ($event) {
            _vm.modal.showModal = true
          },
          ready: _vm.ready,
        },
        scopedSlots: _vm._u([
          {
            key: "main",
            fn: function () {
              return [
                _c("AposModalBody", {
                  scopedSlots: _vm._u([
                    {
                      key: "bodyMain",
                      fn: function () {
                        return [
                          _vm.content.icon
                            ? _c("img", {
                                staticClass: "apos-confirm__custom-logo",
                                attrs: { src: _vm.content.icon, alt: "" },
                              })
                            : _vm.content.icon !== false
                            ? _c("AposLogoIcon", {
                                staticClass: "apos-confirm__logo",
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.content.heading
                            ? _c(
                                "h2",
                                { staticClass: "apos-confirm__heading" },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        _vm.localize(_vm.content.heading)
                                      ) +
                                      "\n        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.content.description
                            ? _c(
                                "p",
                                { staticClass: "apos-confirm__description" },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        _vm.localize(_vm.content.description)
                                      ) +
                                      "\n        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.content.body
                            ? _c(
                                _vm.content.body.component,
                                _vm._b(
                                  { tag: "Component" },
                                  "Component",
                                  _vm.content.body.props,
                                  false
                                )
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.content.form
                            ? _c(
                                "div",
                                { staticClass: "apos-confirm__schema" },
                                [
                                  _vm.formValues
                                    ? _c("AposSchema", {
                                        attrs: {
                                          schema: _vm.content.form.schema,
                                          "trigger-validation": true,
                                        },
                                        model: {
                                          value: _vm.formValues,
                                          callback: function ($$v) {
                                            _vm.formValues = $$v
                                          },
                                          expression: "formValues",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "apos-confirm__btns" },
                            [
                              _vm.mode !== "alert"
                                ? _c("AposButton", {
                                    staticClass: "apos-confirm__btn",
                                    attrs: {
                                      label:
                                        _vm.content.negativeLabel ||
                                        "apostrophe:cancel",
                                    },
                                    on: { click: _vm.cancel },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("AposButton", {
                                ref: "confirm",
                                staticClass: "apos-confirm__btn",
                                attrs: {
                                  label: _vm.affirmativeLabel,
                                  type: _vm.content.theme || "primary",
                                  disabled: _vm.isDisabled,
                                },
                                on: { click: _vm.confirm },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.content.note
                            ? _c("p", { staticClass: "apos-confirm__note" }, [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.localize(_vm.content.note)) +
                                    "\n        "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.mode !== "alert" ? { esc: _vm.cancel } : null
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }