var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "apos-loading" }, [
    _c("svg", { attrs: { viewBox: "0 0 70 20" } }, [
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "gradOne",
                x1: "-0.858289931%",
                y1: "100%",
                x2: "100.756836%",
                y2: "0%",
                spreadMethod: "pad",
              },
            },
            [
              _c("stop", {
                attrs: {
                  offset: "0%",
                  "stop-color": "var(--a-brand-gold)",
                  "stop-opacity": "1",
                },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "100%",
                  "stop-color": "var(--a-brand-red)",
                  "stop-opacity": "1",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "gradTwo",
                x1: "-0.858289931%",
                y1: "100%",
                x2: "100.756836%",
                y2: "0%",
                spreadMethod: "pad",
              },
            },
            [
              _c("stop", {
                attrs: {
                  offset: "0%",
                  "stop-color": "var(--a-brand-red)",
                  "stop-opacity": "1",
                },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "100%",
                  "stop-color": "var(--a-brand-magenta)",
                  "stop-opacity": "1",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "gradThree",
                x1: "-0.858289931%",
                y1: "100%",
                x2: "100.756836%",
                y2: "0%",
                spreadMethod: "pad",
              },
            },
            [
              _c("stop", {
                attrs: {
                  offset: "0%",
                  "stop-color": "var(--a-brand-magenta)",
                  "stop-opacity": "1",
                },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "100%",
                  "stop-color": "var(--a-brand-blue)",
                  "stop-opacity": "1",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("g", [
        _c(
          "circle",
          {
            staticClass: "apos-loading__first",
            attrs: {
              cx: "10",
              cy: "10",
              "stroke-width": "0",
              fill: "url('#gradOne')",
              r: "4.08836",
            },
          },
          [
            _c("animate", {
              attrs: {
                attributeName: "r",
                dur: "950ms",
                values: "3;3;4;5;6;5;4;3",
                repeatCount: "indefinite",
                keyTimes: "0; 0.15; 0.3; 0.45; 0.6; 0.75; 0.9; 1",
                keySplines:
                  ".42 0 1 1; 0 0 .59 1; .42 0 1 1; 0 0 .59 1; .42 0 1 1; 0 0 .59 1; .42 0 1 1; 0 0 .59 1;",
              },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "circle",
          {
            staticClass: "apos-loading__second",
            attrs: {
              cx: "30",
              cy: "10",
              "stroke-width": "0",
              fill: "url('#gradTwo')",
              r: "5.08836",
            },
          },
          [
            _c("animate", {
              attrs: {
                attributeName: "r",
                dur: "950ms",
                values: "4;3;3;4;5;6;5;4",
                repeatCount: "indefinite",
                keyTimes: "0; 0.15; 0.3; 0.45; 0.6; 0.75; 0.9; 1",
                keySplines:
                  ".42 0 1 1; 0 0 .59 1; .42 0 1 1; 0 0 .59 1; .42 0 1 1; 0 0 .59 1; .42 0 1 1; 0 0 .59 1;",
              },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "circle",
          {
            staticClass: "apos-loading__third",
            attrs: {
              cx: "50",
              cy: "10",
              "stroke-width": "0",
              fill: "url('#gradThree')",
              r: "5.91164",
            },
          },
          [
            _c("animate", {
              attrs: {
                attributeName: "r",
                dur: "950ms",
                values: "5;4;3;3;4;5;6;5",
                repeatCount: "indefinite",
                keyTimes: "0; 0.15; 0.3; 0.45; 0.6; 0.75; 0.9; 1",
                keySplines:
                  ".42 0 1 1; 0 0 .59 1; .42 0 1 1; 0 0 .59 1; .42 0 1 1; 0 0 .59 1; .42 0 1 1; 0 0 .59 1;",
              },
            }),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }