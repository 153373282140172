var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      directives: [
        {
          name: "apos-tooltip",
          rawName: "v-apos-tooltip",
          value: _vm.tooltip,
          expression: "tooltip",
        },
      ],
      staticClass: "apos-indicator",
      attrs: { "aria-hidden": _vm.decorative },
    },
    [
      _c(_vm.icon, {
        tag: "component",
        staticClass: "apos-indicator__icon",
        attrs: {
          size: _vm.iconSize,
          title: _vm.title ? _vm.title : "",
          "fill-color": _vm.iconColor,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }