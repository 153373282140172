var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "collapse", duration: 300 } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showSelectAll,
            expression: "showSelectAll",
          },
        ],
        staticClass: "apos-select-box",
      },
      [
        _c("div", { staticClass: "apos-select-box__content" }, [
          _c(
            "p",
            { staticClass: "apos-select-box__text" },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.selectBoxMessage) + "\n        "
              ),
              !_vm.allPiecesSelection.isSelected
                ? _c("AposButton", {
                    staticClass: "apos-select-box__select-all",
                    attrs: {
                      type: "subtle",
                      modifiers: ["inline", "small", "no-motion"],
                      label: _vm.selectBoxMessageButton,
                      "text-color": "var(--a-primary)",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("select-all")
                      },
                    },
                  })
                : _c("AposButton", {
                    staticClass: "apos-select-box__select-all",
                    attrs: {
                      type: "subtle",
                      modifiers: ["inline", "small", "no-motion"],
                      label: "apostrophe:clearSelection",
                      "text-color": "var(--a-primary)",
                    },
                    on: { click: _vm.clearSelection },
                  }),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }