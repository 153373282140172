"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isOneOf = void 0;
/** Validator that only allows any of the given values. */
const isOneOf = (allowedValues) => (value) => {
    if (!allowedValues.includes(value)) {
        return `value should be one of "${allowedValues.join('", "')}"`;
    }
    return undefined;
};
exports.isOneOf = isOneOf;
