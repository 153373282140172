var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "apos-spinner", class: _vm.className }, [
    _vm.weight === "heavy"
      ? _c(
          "svg",
          {
            staticClass: "apos-spinner__svg apos-spinner__svg--heavy",
            style: _vm.style,
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 23 23",
            },
          },
          [
            _c(
              "defs",
              [
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "linear-gradient",
                      x1: "-280.32",
                      y1: "390.11",
                      x2: "-279.82",
                      y2: "390.9",
                      gradientTransform:
                        "matrix(22.2, 0, 0, -22.2, 6233.2, 8673.92)",
                      gradientUnits: "userSpaceOnUse",
                    },
                  },
                  [
                    _c("stop", {
                      attrs: { offset: "0", "stop-color": "#cca9ff" },
                    }),
                    _vm._v(" "),
                    _c("stop", {
                      attrs: { offset: "1", "stop-color": "#6516dd" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "linear-gradient-2",
                      x1: "-276.41",
                      y1: "379.82",
                      x2: "-276.72",
                      y2: "379.33",
                      gradientTransform:
                        "matrix(17.69, 0, 0, -13.7, 4908.02, 5205.92)",
                      gradientUnits: "userSpaceOnUse",
                    },
                  },
                  [
                    _c("stop", {
                      attrs: { offset: "0", "stop-color": "#c8a2ff" },
                    }),
                    _vm._v(" "),
                    _c("stop", {
                      attrs: { offset: "1", "stop-color": "#6516dd" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("path", {
              staticClass: "apos-spinner__svg-track",
              attrs: {
                d: "M11.5.32a11.1,11.1,0,1,0,11.1,11.1A11.1,11.1,0,0,0,11.5.32Zm0,5.2a5.9,5.9,0,1,1-5.9,5.9A5.9,5.9,0,0,1,11.5,5.52Z",
                fill: "url(#linear-gradient)",
              },
            }),
            _vm._v(" "),
            _c("path", {
              staticClass: "apos-spinner__svg-spinner",
              attrs: {
                d: "M11.5.32a11.1,11.1,0,0,1,11.1,11.1,2.6,2.6,0,0,1-5.19.17v-.17a5.9,5.9,0,0,0-5.9-5.9A5.83,5.83,0,0,0,9,6.07l-.29.14A2.6,2.6,0,1,1,6.29,1.62,11,11,0,0,1,11.5.32Z",
                fill: "url(#linear-gradient-2)",
              },
            }),
          ]
        )
      : _c(
          "svg",
          {
            staticClass: "apos-spinner__svg",
            style: _vm.style,
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: "38",
              height: "38",
              viewBox: "0 0 38 38",
            },
          },
          [
            _c(
              "defs",
              [
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      x1: "8.042%",
                      y1: "0%",
                      x2: "65.682%",
                      y2: "23.865%",
                      id: "a",
                    },
                  },
                  [
                    _c("stop", {
                      attrs: {
                        "stop-color": "currentColor",
                        "stop-opacity": "0",
                        offset: "0%",
                      },
                    }),
                    _vm._v(" "),
                    _c("stop", {
                      attrs: {
                        "stop-color": "currentColor",
                        "stop-opacity": ".631",
                        offset: "63.146%",
                      },
                    }),
                    _vm._v(" "),
                    _c("stop", {
                      attrs: { "stop-color": "currentColor", offset: "100%" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
              _c("g", { attrs: { transform: "translate(1 1)" } }, [
                _c(
                  "path",
                  {
                    attrs: {
                      d: "M36 18c0-9.94-8.06-18-18-18",
                      id: "Oval-2",
                      stroke: "url(#a)",
                      "stroke-width": "2",
                      transform: "rotate(266.312 18 18)",
                    },
                  },
                  [
                    _c("animateTransform", {
                      attrs: {
                        attributeName: "transform",
                        type: "rotate",
                        from: "0 18 18",
                        to: "360 18 18",
                        dur: "0.9s",
                        repeatCount: "indefinite",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "circle",
                  {
                    attrs: {
                      fill: "currentColor",
                      cx: "36",
                      cy: "18",
                      r: "1",
                      transform: "rotate(266.312 18 18)",
                    },
                  },
                  [
                    _c("animateTransform", {
                      attrs: {
                        attributeName: "transform",
                        type: "rotate",
                        from: "0 18 18",
                        to: "360 18 18",
                        dur: "0.9s",
                        repeatCount: "indefinite",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }