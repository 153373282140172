var render = function render(_c, _vm) {
  return _c(
    "span",
    _vm._g(
      _vm._b(
        {
          staticClass: "material-design-icon cloud-upload-icon",
          class: [_vm.data.class, _vm.data.staticClass],
          attrs: {
            "aria-hidden": _vm.props.decorative,
            "aria-label": _vm.props.title,
            role: "img",
          },
        },
        "span",
        _vm.data.attrs,
        false
      ),
      _vm.listeners
    ),
    [
      _c(
        "svg",
        {
          staticClass: "material-design-icon__svg",
          attrs: {
            fill: _vm.props.fillColor,
            width: _vm.props.size,
            height: _vm.props.size,
            viewBox: "0 0 24 24",
          },
        },
        [
          _c(
            "path",
            {
              attrs: {
                d: "M14,13V17H10V13H7L12,8L17,13M19.35,10.03C18.67,6.59 15.64,4 12,4C9.11,4 6.6,5.64 5.35,8.03C2.34,8.36 0,10.9 0,14A6,6 0 0,0 6,20H19A5,5 0 0,0 24,15C24,12.36 21.95,10.22 19.35,10.03Z",
              },
            },
            [
              !_vm.props.decorative
                ? _c("title", [_vm._v(_vm._s(_vm.props.title))])
                : _vm._e(),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }