var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AposInputWrapper", {
    attrs: {
      modifiers: _vm.modifiers,
      field: _vm.field,
      error: _vm.effectiveError,
      uid: _vm.uid,
      "display-options": _vm.displayOptions,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { staticClass: "apos-input-wrapper" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.next,
                    expression: "next",
                  },
                ],
                staticClass: "apos-input apos-input--password",
                attrs: {
                  type: "password",
                  placeholder: _vm.$t(_vm.field.placeholder),
                  disabled: _vm.field.readOnly,
                  required: _vm.field.required,
                  id: _vm.uid,
                  tabindex: _vm.tabindex,
                  autocomplete: _vm.field.autocomplete,
                },
                domProps: { value: _vm.next },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.emitReturn.apply(null, arguments)
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.next = $event.target.value
                  },
                },
              }),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }