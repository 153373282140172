var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.passwordResetEnabled
    ? _c(
        "div",
        { staticClass: "apos-login-form" },
        [
          _c("TheAposLoginHeader", {
            attrs: {
              env: _vm.context.env,
              title: _vm.$t("apostrophe:loginResetPassword"),
              subtitle: _vm.context.name,
              help: _vm.help,
              error: _vm.$t(_vm.error),
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "apos-login-form__body",
              attrs: { "data-apos-test": "pwdResetRequestForm" },
            },
            [
              _vm.done
                ? _c("AposButton", {
                    staticClass: "apos-login-form__submit",
                    attrs: {
                      "data-apos-test": "loginBack",
                      busy: _vm.busy,
                      type: "primary",
                      label: "apostrophe:loginBack",
                      modifiers: ["block"],
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("set-stage", "login")
                      },
                    },
                  })
                : _c(
                    "form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.submit.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("AposSchema", {
                        attrs: { schema: _vm.schema },
                        model: {
                          value: _vm.doc,
                          callback: function ($$v) {
                            _vm.doc = $$v
                          },
                          expression: "doc",
                        },
                      }),
                      _vm._v(" "),
                      _c("AposButton", {
                        staticClass: "apos-login-form__submit",
                        attrs: {
                          "data-apos-test": "pwdResetRequestSubmit",
                          busy: _vm.busy,
                          disabled: _vm.disabled,
                          type: "primary",
                          label: "apostrophe:loginSendEmail",
                          "button-type": "submit",
                          modifiers: ["gradient-on-hover", "block"],
                        },
                        on: { click: _vm.submit },
                      }),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }