"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.integerProp = void 0;
const util_1 = require("../util");
const validators_1 = require("../validators");
/**
 * Allows any integer (validated at runtime).
 *
 * @param validator - Optional function for further runtime validation; should return `undefined` if valid, or an error string if invalid.
 */
const integerProp = (validator) => (0, util_1.propOptionsGenerator)(Number, validator, validators_1.isInteger);
exports.integerProp = integerProp;
