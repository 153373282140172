"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNonPositive = void 0;
/** Validator that only allows non-positive numbers (`<= 0`). */
const isNonPositive = (value) => {
    if (typeof value !== 'number' || value > 0 || Number.isNaN(value)) {
        return 'value should be a non-positive number';
    }
    return undefined;
};
exports.isNonPositive = isNonPositive;
