var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AposInputWrapper", {
    attrs: {
      field: _vm.field,
      error: _vm.effectiveError,
      uid: _vm.uid,
      items: _vm.next,
      "display-options": _vm.displayOptions,
      modifiers: _vm.modifiers,
    },
    scopedSlots: _vm._u([
      {
        key: "additional",
        fn: function () {
          return [
            _vm.minSize[0] || _vm.minSize[1]
              ? _c("div", { staticClass: "apos-field__min-size" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$t("apostrophe:minSize", {
                          width: _vm.minSize[0] || "???",
                          height: _vm.minSize[1] || "???",
                        })
                      ) +
                      "\n    "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.field.max > 1
              ? _c("AposMinMaxCount", {
                  attrs: { field: _vm.field, value: _vm.next },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "apos-input-wrapper apos-input-relationship" },
              [
                _c(
                  "div",
                  { staticClass: "apos-input-relationship__input-wrapper" },
                  [
                    !_vm.modifiers.includes("no-search")
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchTerm,
                              expression: "searchTerm",
                            },
                          ],
                          staticClass:
                            "apos-input apos-input--text apos-input--relationship",
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t(_vm.placeholder),
                            disabled: _vm.field.readOnly || _vm.limitReached,
                            required: _vm.field.required,
                            id: _vm.uid,
                            tabindex: "0",
                          },
                          domProps: { value: _vm.searchTerm },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.searchTerm = $event.target.value
                              },
                              _vm.input,
                            ],
                            focus: _vm.input,
                            focusout: _vm.handleFocusOut,
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.field.browse !== false
                      ? _c("AposButton", {
                          staticClass: "apos-input-relationship__button",
                          attrs: {
                            disabled: _vm.field.readOnly || _vm.limitReached,
                            label: _vm.browseLabel,
                            modifiers: _vm.buttonModifiers,
                            type: "input",
                          },
                          on: { click: _vm.choose },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.next.length
                  ? _c("AposSlatList", {
                      staticClass: "apos-input-relationship__items",
                      attrs: {
                        value: _vm.next,
                        duplicate: _vm.duplicate,
                        disabled: _vm.field.readOnly,
                        "relationship-schema": _vm.field.schema,
                        "editor-label": _vm.field.editorLabel,
                        "editor-icon": _vm.field.editorIcon,
                      },
                      on: {
                        input: _vm.updateSelected,
                        "item-clicked": _vm.editRelationship,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("AposSearchList", {
                  attrs: {
                    list: _vm.searchList,
                    "selected-items": _vm.next,
                    icon: _vm.field.suggestionIcon,
                    "icon-size": _vm.field.suggestionIconSize,
                    fields: _vm.field.suggestionFields,
                    "disabled-tooltip": "apostrophe:publishBeforeUsingTooltip",
                  },
                  on: { select: _vm.updateSelected },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }