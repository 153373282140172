"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isInstanceOf = void 0;
/** Validator that only allows instances of the given parent. */
const isInstanceOf = (parent) => (value) => {
    if (!(value instanceof parent)) {
        return `value should be an instance of ${parent.name}`;
    }
    return undefined;
};
exports.isInstanceOf = isInstanceOf;
