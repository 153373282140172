var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "apos-table__cell-field apos-table__cell-field--context-menu",
    },
    [
      _c(
        "span",
        {
          staticClass: "apos-table__cell-field--context-menu__content",
          class: _vm.classes,
        },
        [
          _c("AposDocContextMenu", {
            attrs: {
              disabled: _vm.disabled,
              doc: _vm.draft,
              published: _vm.published,
              "show-edit": _vm.options.showEdit,
              "show-preview": _vm.options.showPreview,
              "show-discard-draft": _vm.options.showDiscardDraft,
              "show-copy": _vm.options.showCreate,
              "show-archive": _vm.options.showArchive,
              "show-restore": _vm.options.showRestore,
              "show-dismiss-submission": _vm.options.showDismissSubmission,
              "can-delete-draft": _vm.options.canDeleteDraft,
            },
            on: {
              "menu-open": function ($event) {
                _vm.menuOpen = true
              },
              "menu-close": function ($event) {
                _vm.menuOpen = false
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }