var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AposModal", {
    staticClass: "apos-widget-editor",
    attrs: { modal: _vm.modal, "modal-title": _vm.editLabel },
    on: {
      inactive: function ($event) {
        _vm.modal.active = false
      },
      "show-modal": function ($event) {
        _vm.modal.showModal = true
      },
      esc: _vm.confirmAndCancel,
      "no-modal": function ($event) {
        return _vm.$emit("safe-close")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "breadcrumbs",
        fn: function () {
          return [
            _vm.breadcrumbs && _vm.breadcrumbs.length
              ? _c("AposModalBreadcrumbs", {
                  attrs: { items: _vm.breadcrumbs },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "main",
        fn: function () {
          return [
            _c("AposModalBody", {
              scopedSlots: _vm._u([
                {
                  key: "bodyMain",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "apos-widget-editor__body" },
                        [
                          _c("AposSchema", {
                            ref: "schema",
                            attrs: {
                              "trigger-validation": _vm.triggerValidation,
                              schema: _vm.schema,
                              value: _vm.docFields,
                              meta: _vm.meta,
                              "following-values": _vm.followingValues(),
                              "conditional-fields": _vm.conditionalFields,
                            },
                            on: {
                              input: _vm.updateDocFields,
                              validate: _vm.triggerValidate,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c("AposButton", {
              attrs: { type: "default", label: "apostrophe:cancel" },
              on: { click: _vm.confirmAndCancel },
            }),
            _vm._v(" "),
            _c("AposButton", {
              attrs: {
                type: "primary",
                label: _vm.saveLabel,
                disabled: _vm.docFields.hasErrors,
              },
              on: { click: _vm.save },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }