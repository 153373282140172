var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "apos-context-menu__tip",
      class: _vm.alignmentModifier,
      attrs: {
        width: "27px",
        height: "11px",
        viewBox: "0 0 27 11",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            width: "27px",
            height: "11px",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c("path", {
            staticClass: "apos-context-menu__tip-outline",
            attrs: {
              d: "M17.2842712,1.46446609 L25.748,9.928 L1.749,9.928 L10.2132034,1.46446609 C12.1658249,-0.488155365 15.3316498,-0.488155365 17.2842712,1.46446609 Z",
              stroke: "var(--a-base-8)",
            },
          }),
          _vm._v(" "),
          _c("path", {
            staticClass: "apos-context-menu__tip-background",
            attrs: {
              d: "M17.0029602,1.84623992 C15.3903198,0.179595947 12.5749711,0.0148310371 10.7918701,1.61499023 C9.60313614,2.68176303 9.52086075,2.75884626 10.5450439,1.84623992 L0.815307617,11.4361572 L26.6676025,11.4361572 L17.0029602,1.84623992 Z",
              fill: "var(--a-background-primary)",
              "fill-rule": "nonzero",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }