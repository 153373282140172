var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "apos-context-menu" },
    [
      _vm._t("prebutton"),
      _vm._v(" "),
      _c(
        "v-popover",
        {
          attrs: {
            offset: _vm.menuOffset,
            trigger: "manual",
            placement: _vm.menuPlacement,
            open: _vm.isOpen,
            delay: { show: 0, hide: 0 },
            "popover-class": _vm.popoverClass,
            "popover-wrapper-class": "apos-popover__wrapper",
            "popover-inner-class": "apos-popover__inner",
          },
          on: { hide: _vm.hide, show: _vm.show },
          scopedSlots: _vm._u(
            [
              {
                key: "popover",
                fn: function () {
                  return [
                    _c(
                      "AposContextMenuDialog",
                      {
                        attrs: {
                          "menu-placement": _vm.menuPlacement,
                          "class-list": _vm.classList,
                          menu: _vm.menu,
                        },
                        on: { "item-clicked": _vm.menuItemClicked },
                      },
                      [_vm._t("default")],
                      2
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        [
          _c(
            "AposButton",
            _vm._b(
              {
                ref: "button",
                staticClass: "apos-context-menu__btn",
                attrs: {
                  "data-apos-test": "contextMenuTrigger",
                  state: _vm.buttonState,
                  disabled: _vm.disabled,
                  tooltip: _vm.tooltip,
                  role: "button",
                  attrs: {
                    "aria-haspopup": "menu",
                    "aria-expanded": _vm.isOpen ? true : false,
                  },
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.buttonClicked($event)
                  },
                },
              },
              "AposButton",
              _vm.button,
              false
            )
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }