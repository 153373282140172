var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.themeClass, attrs: { id: "apos-modals" } },
    _vm._l(_vm.stack, function (modal) {
      return _c(
        modal.componentName,
        _vm._b(
          {
            key: modal.id,
            tag: "component",
            on: {
              "modal-result": function ($event) {
                modal.result = $event
              },
              "safe-close": function ($event) {
                return _vm.resolve(modal)
              },
            },
          },
          "component",
          modal.props,
          false
        )
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }