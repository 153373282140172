var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "apos-subform", class: { "apos-separator": _vm.separator } },
    [
      _c("transition", { attrs: { name: "slide-fade" } }, [
        _vm.expanded
          ? _c(
              "div",
              { staticClass: "apos-subform__schema" },
              [
                _vm.subform.label
                  ? _c("span", { staticClass: "apos-subform__schema-label" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t(_vm.subform.label)) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("AposSchema", {
                  ref: "schema",
                  class: { "apos-subform__disabled": _vm.busy },
                  attrs: {
                    "data-apos-test": "subformSchema",
                    "data-apos-test-name": _vm.subform.name,
                    "trigger-validation": _vm.triggerValidation,
                    schema: _vm.schema,
                    value: _vm.docFields,
                    "following-values": _vm.followingValues(),
                    "conditional-fields": _vm.conditionalFields,
                    "server-errors": _vm.serverErrors,
                    modifiers: ["small"],
                  },
                  on: {
                    input: _vm.updateDocFields,
                    validate: _vm.triggerValidate,
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "apos-subform__controls" },
                  [
                    _c("AposButton", {
                      attrs: {
                        "data-apos-test": "subformCancel",
                        disabled: _vm.busy,
                        type: "subtle",
                        label: "apostrophe:cancel",
                      },
                      on: { click: _vm.cancel },
                    }),
                    _vm._v(" "),
                    _c("AposButton", {
                      attrs: {
                        "data-apos-test": "subformSubmit",
                        disabled: _vm.busy || _vm.docFields.hasErrors,
                        type: "primary",
                        label: "apostrophe:save",
                      },
                      on: { click: _vm.submit },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      !_vm.expanded
        ? _c("div", { staticClass: "apos-subform__preview" }, [
            _c(
              "div",
              { staticClass: "apos-subform__preview-grid" },
              [
                _c("AposSubformPreview", {
                  attrs: { subform: _vm.subform, values: _vm.values },
                }),
                _vm._v(" "),
                _vm.updateIndicator && !_vm.triggerHover
                  ? _c(
                      "span",
                      { staticClass: "apos-subform__preview-update-indicator" },
                      [_vm._v(_vm._s(_vm.$t("apostrophe:updated")))]
                    )
                  : _vm.triggerHover
                  ? _c(
                      "span",
                      { staticClass: "apos-subform__preview-edit-indicator" },
                      [_vm._v(_vm._s(_vm.$t("apostrophe:edit")))]
                    )
                  : _c("AposIndicator", {
                      staticClass: "apos-subform__preview-icon",
                      attrs: { icon: "chevron-down-icon" },
                    }),
              ],
              1
            ),
            _vm._v(" "),
            _c("button", {
              staticClass: "apos-subform__preview-trigger",
              on: {
                click: _vm.toggleExpanded,
                mouseenter: function ($event) {
                  _vm.triggerHover = true
                },
                mouseleave: function ($event) {
                  _vm.triggerHover = false
                },
              },
            }),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }