var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "apos-primary-scrollbar apos-context-menu__dialog",
      class: _vm.classes,
      attrs: { role: "dialog" },
    },
    [
      _vm.hasTip
        ? _c("AposContextMenuTip", {
            attrs: {
              align: _vm.tipAlignment,
              origin: _vm.menuOrigin,
              "x-placement": "bottom",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "apos-context-menu__pane" },
        [
          _vm._t("default", function () {
            return [
              _vm.menu
                ? _c(
                    "ul",
                    {
                      staticClass: "apos-context-menu__items",
                      attrs: { role: "menu" },
                    },
                    _vm._l(_vm.menu, function (item) {
                      return _c("AposContextMenuItem", {
                        key: item.action,
                        attrs: {
                          "data-apos-test-context-menu-item": item.action,
                          "menu-item": item,
                          open: _vm.isOpen,
                        },
                        on: { clicked: _vm.menuItemClicked },
                      })
                    }),
                    1
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }