var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AposInputWrapper", {
    attrs: {
      field: _vm.field,
      error: null,
      uid: _vm.uid,
      "display-options": _vm.displayOptions,
      meta: _vm.objectMeta,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { staticClass: "apos-input-object" }, [
              _c(
                "div",
                { staticClass: "apos-input-wrapper" },
                [
                  _c("AposSchema", {
                    ref: "schema",
                    attrs: {
                      meta: _vm.currentDocMeta,
                      schema: _vm.schema,
                      "trigger-validation": _vm.triggerValidation,
                      generation: _vm.generation,
                      "doc-id": _vm.docId,
                      "conditional-fields": _vm.conditionalFields,
                      "following-values": _vm.followingValuesWithParent,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.evaluateConditions(_vm.values)
                      },
                      validate: function ($event) {
                        return _vm.emitValidate()
                      },
                    },
                    model: {
                      value: _vm.schemaInput,
                      callback: function ($$v) {
                        _vm.schemaInput = $$v
                      },
                      expression: "schemaInput",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }