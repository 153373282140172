var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      directives: [
        {
          name: "apos-tooltip",
          rawName: "v-apos-tooltip",
          value: _vm.tooltip,
          expression: "tooltip",
        },
      ],
      staticClass: "apos-button__wrapper",
      class: { "apos-button__wrapper--block": _vm.modifiers.includes("block") },
      attrs: { "data-apos-test": _vm.actionTestLabel },
    },
    [
      _c(
        _vm.href ? "a" : "button",
        _vm._g(
          _vm._b(
            {
              tag: "component",
              staticClass: "apos-button",
              class: _vm.modifierClass,
              style: { color: _vm.textColor },
              attrs: {
                href: _vm.href.length ? _vm.href : false,
                tabindex: _vm.tabindex,
                disabled: _vm.isDisabled,
                type: _vm.buttonType,
                role: _vm.role,
                id: _vm.attrs.id ? _vm.attrs.id : _vm.id,
              },
            },
            "component",
            _vm.attrs,
            false
          ),
          _vm.href ? {} : { click: _vm.click }
        ),
        [
          _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              _vm.busy
                ? _c("AposSpinner", { attrs: { color: _vm.spinnerColor } })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.colorStyle
            ? _c("span", { staticClass: "apos-button__color-preview" }, [
                _c("span", {
                  staticClass: "apos-button__color-preview__swatch",
                  style: _vm.colorStyle,
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass: "apos-button__color-preview__checkerboard",
                }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "apos-button__content" },
            [
              _vm.icon
                ? _c("AposIndicator", {
                    staticClass: "apos-button__icon",
                    attrs: {
                      icon: _vm.icon,
                      "icon-size": _vm.iconSize,
                      "fill-color": "currentColor",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._t("label", function () {
                return [
                  _c(
                    "span",
                    {
                      staticClass: "apos-button__label",
                      class: {
                        "apos-sr-only": _vm.iconOnly || _vm.type === "color",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t(_vm.label, _vm.interpolate)) +
                          "\n        "
                      ),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }