var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.phase === "beforeSubmit" || _vm.phase === "uponSubmit"
    ? _c(
        "div",
        { key: "1", staticClass: "apos-login-form" },
        [
          _c("TheAposLoginHeader", {
            attrs: {
              env: _vm.context.env,
              title: _vm.context.name,
              error: _vm.$t(_vm.error),
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "apos-login-form__body" }, [
            _c(
              "form",
              {
                attrs: { "data-apos-test": "loginForm" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _c("AposSchema", {
                  attrs: { schema: _vm.schema },
                  model: {
                    value: _vm.doc,
                    callback: function ($$v) {
                      _vm.doc = $$v
                    },
                    expression: "doc",
                  },
                }),
                _vm._v(" "),
                _vm.passwordResetEnabled
                  ? _c(
                      "a",
                      {
                        staticClass: "apos-login-form__link",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.$emit("set-stage", "forgotPassword")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("apostrophe:loginResetPassword")))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.beforeSubmitRequirements, function (requirement) {
                  return _c(
                    requirement.component,
                    _vm._b(
                      {
                        key: requirement.name,
                        tag: "Component",
                        on: {
                          done: function ($event) {
                            return _vm.requirementDone(requirement, $event)
                          },
                          block: function ($event) {
                            return _vm.requirementBlock(requirement)
                          },
                        },
                      },
                      "Component",
                      _vm.getRequirementProps(requirement.name),
                      false
                    )
                  )
                }),
                _vm._v(" "),
                _vm.phase === "uponSubmit"
                  ? _vm._l(_vm.uponSubmitRequirements, function (requirement) {
                      return _c(
                        requirement.component,
                        _vm._b(
                          {
                            key: requirement.name,
                            tag: "Component",
                            on: {
                              done: function ($event) {
                                return _vm.requirementDone(requirement, $event)
                              },
                              block: function ($event) {
                                return _vm.requirementBlock(requirement)
                              },
                            },
                          },
                          "Component",
                          _vm.getRequirementProps(requirement.name),
                          false
                        )
                      )
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("AposButton", {
                  staticClass: "apos-login-form__submit",
                  attrs: {
                    "data-apos-test": "loginSubmit",
                    busy: _vm.busy,
                    disabled: _vm.disabled,
                    type: "primary",
                    label: "apostrophe:login",
                    "button-type": "submit",
                    modifiers: ["gradient-on-hover", "block"],
                  },
                  on: { click: _vm.submit },
                }),
              ],
              2
            ),
          ]),
        ],
        1
      )
    : _vm.activeSoloRequirement
    ? _c(
        "div",
        { key: "2", staticClass: "apos-login-form" },
        [
          _c("TheAposLoginHeader", {
            attrs: {
              env: _vm.context.env,
              title: _vm.context.name,
              error: _vm.$t(_vm.error),
              tiny: true,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "apos-login-form__body" },
            [
              !_vm.fetchingRequirementProps
                ? _c(
                    _vm.activeSoloRequirement.component,
                    _vm._b(
                      {
                        tag: "Component",
                        attrs: {
                          success: _vm.activeSoloRequirement.success,
                          error: _vm.activeSoloRequirement.error,
                        },
                        on: {
                          done: function ($event) {
                            return _vm.requirementDone(
                              _vm.activeSoloRequirement,
                              $event
                            )
                          },
                          confirm: function ($event) {
                            return _vm.requirementConfirmed(
                              _vm.activeSoloRequirement
                            )
                          },
                        },
                      },
                      "Component",
                      _vm.getRequirementProps(_vm.activeSoloRequirement.name),
                      false
                    )
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }