var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "widget",
      staticClass: "apos-area-widget-wrapper",
      class: { "apos-area-widget-wrapper--foreign": _vm.foreign },
      attrs: {
        "data-area-widget": _vm.widget._id,
        "data-area-label": _vm.widgetLabel,
        "data-apos-widget-foreign": _vm.foreign ? 1 : 0,
        "data-apos-widget-id": _vm.widget._id,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "apos-area-widget-inner",
          class: _vm.containerClasses,
          on: {
            mouseover: function ($event) {
              return _vm.mouseover($event)
            },
            mouseleave: _vm.mouseleave,
            click: function ($event) {
              return _vm.getFocus($event, _vm.widget._id)
            },
          },
        },
        [
          _c(
            "div",
            {
              ref: "label",
              staticClass: "apos-area-widget-controls apos-area-widget__label",
              class: _vm.labelsClasses,
            },
            [
              _c(
                "ol",
                { staticClass: "apos-area-widget__breadcrumbs" },
                [
                  _c(
                    "li",
                    {
                      staticClass:
                        "apos-area-widget__breadcrumb apos-area-widget__breadcrumb--widget-icon",
                    },
                    [
                      _c("AposIndicator", {
                        attrs: { icon: _vm.widgetIcon, "icon-size": 13 },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.breadcrumbs.list, function (item, index) {
                    return _c(
                      "li",
                      {
                        key: item.id,
                        staticClass: "apos-area-widget__breadcrumb",
                        attrs: {
                          "data-apos-widget-breadcrumb":
                            _vm.breadcrumbs.list.length - index,
                        },
                      },
                      [
                        _c("AposButton", {
                          attrs: {
                            type: "quiet",
                            label: item.label,
                            icon: "chevron-right-icon",
                            "icon-size": 9,
                            modifiers: ["icon-right", "no-motion"],
                          },
                          on: {
                            click: function ($event) {
                              return _vm.getFocus($event, item.id)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      staticClass: "apos-area-widget__breadcrumb",
                      attrs: { "data-apos-widget-breadcrumb": "0" },
                    },
                    [
                      _c("AposButton", {
                        attrs: {
                          type: "quiet",
                          label: _vm.foreign
                            ? {
                                key: "apostrophe:editWidgetType",
                                label: _vm.$t(_vm.widgetLabel),
                              }
                            : _vm.widgetLabel,
                          tooltip:
                            !_vm.isContextual &&
                            "apostrophe:editWidgetForeignTooltip",
                          "icon-size": 11,
                          modifiers: ["no-motion"],
                        },
                        on: {
                          click: function ($event) {
                            _vm.foreign ? _vm.$emit("edit", _vm.i) : null
                          },
                        },
                        nativeOn: {
                          dblclick: function ($event) {
                            !_vm.foreign && !_vm.isContextual
                              ? _vm.$emit("edit", _vm.i)
                              : null
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "apos-area-widget-controls apos-area-widget-controls--add apos-area-widget-controls--add--top",
              class: _vm.addClasses,
            },
            [
              !_vm.foreign
                ? _c("AposAreaMenu", {
                    attrs: {
                      "max-reached": _vm.maxReached,
                      "context-menu-options": _vm.contextMenuOptions,
                      index: _vm.i,
                      "widget-options": _vm.widgets,
                      options: _vm.options,
                      disabled: _vm.disabled,
                    },
                    on: {
                      add: function ($event) {
                        return _vm.$emit("add", $event)
                      },
                      "menu-open": function ($event) {
                        return _vm.toggleMenuFocus($event, "top", true)
                      },
                      "menu-close": function ($event) {
                        return _vm.toggleMenuFocus($event, "top", false)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "apos-area-widget-controls apos-area-widget-controls--modify",
              class: _vm.controlsClasses,
            },
            [
              !_vm.foreign
                ? _c("AposWidgetControls", {
                    attrs: {
                      first: _vm.i === 0,
                      last: _vm.i === _vm.next.length - 1,
                      options: { contextual: _vm.isContextual },
                      foreign: _vm.foreign,
                      disabled: _vm.disabled,
                      "max-reached": _vm.maxReached,
                    },
                    on: {
                      up: function ($event) {
                        return _vm.$emit("up", _vm.i)
                      },
                      remove: function ($event) {
                        return _vm.$emit("remove", _vm.i)
                      },
                      edit: function ($event) {
                        return _vm.$emit("edit", _vm.i)
                      },
                      cut: function ($event) {
                        return _vm.$emit("cut", _vm.i)
                      },
                      copy: function ($event) {
                        return _vm.$emit("copy", _vm.i)
                      },
                      clone: function ($event) {
                        return _vm.$emit("clone", _vm.i)
                      },
                      down: function ($event) {
                        return _vm.$emit("down", _vm.i)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "apos-area-widget-guard",
            class: { "apos-is-disabled": _vm.isFocused },
          }),
          _vm._v(" "),
          _vm.isContextual && !_vm.foreign
            ? _c(_vm.widgetEditorComponent(_vm.widget.type), {
                key: _vm.generation,
                tag: "component",
                attrs: {
                  options: _vm.widgetOptions,
                  type: _vm.widget.type,
                  value: _vm.widget,
                  meta: _vm.meta,
                  "doc-id": _vm.docId,
                  focused: _vm.isFocused,
                },
                on: {
                  update: function ($event) {
                    return _vm.$emit("update", $event)
                  },
                },
              })
            : _c(_vm.widgetComponent(_vm.widget.type), {
                key: `${_vm.generation}-preview`,
                tag: "component",
                attrs: {
                  options: _vm.widgetOptions,
                  type: _vm.widget.type,
                  id: _vm.widget._id,
                  "area-field-id": _vm.fieldId,
                  "area-field": _vm.field,
                  "following-values": _vm.followingValuesWithParent,
                  value: _vm.widget,
                  meta: _vm.meta,
                  foreign: _vm.foreign,
                  "doc-id": _vm.docId,
                  rendering: _vm.rendering,
                },
                on: {
                  edit: function ($event) {
                    return _vm.$emit("edit", _vm.i)
                  },
                },
              }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "apos-area-widget-controls apos-area-widget-controls--add apos-area-widget-controls--add--bottom",
              class: _vm.addClasses,
            },
            [
              !_vm.foreign
                ? _c("AposAreaMenu", {
                    attrs: {
                      "max-reached": _vm.maxReached,
                      "context-menu-options": _vm.bottomContextMenuOptions,
                      index: _vm.i + 1,
                      "widget-options": _vm.widgets,
                      options: _vm.options,
                      disabled: _vm.disabled,
                    },
                    on: {
                      add: function ($event) {
                        return _vm.$emit("add", $event)
                      },
                      "menu-open": function ($event) {
                        return _vm.toggleMenuFocus($event, "bottom", true)
                      },
                      "menu-close": function ($event) {
                        return _vm.toggleMenuFocus($event, "bottom", false)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }