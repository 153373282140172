var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AposContextMenu",
    { attrs: { button: _vm.button, "menu-placement": "bottom" } },
    [
      _c(
        "div",
        { staticClass: "apos-filters-menu" },
        _vm._l(_vm.filterSets, function (set) {
          return _c(
            "div",
            { key: set.key, staticClass: "apos-filters-menu__set" },
            [
              _c(_vm.map[set.field.type], {
                tag: "component",
                attrs: {
                  field: set.field,
                  value: set.value,
                  status: set.status,
                  icon:
                    set.field.type === "select"
                      ? "unfold-more-horizontal-icon"
                      : "",
                },
                on: {
                  input: function ($event) {
                    return _vm.input($event, set.name)
                  },
                },
              }),
            ],
            1
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }