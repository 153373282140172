var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AposInputWrapper", {
    attrs: {
      field: _vm.field,
      error: _vm.effectiveError,
      uid: _vm.uid,
      "display-options": _vm.displayOptions,
      modifiers: [..._vm.modifiers, "full-width"],
      items: _vm.next.items,
      meta: _vm.areaMeta,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass: "apos-input-wrapper",
                class: !_vm.next.items.length ? "apos-is-empty" : null,
                attrs: { "data-apos-schema-area": "" },
              },
              [
                _c(_vm.editorComponent, {
                  tag: "Component",
                  attrs: {
                    options: _vm.field.options,
                    items: _vm.next.items,
                    meta: _vm.areaMeta,
                    choices: _vm.choices,
                    id: _vm.next._id,
                    "field-id": _vm.field._id,
                    field: _vm.field,
                    "following-values": _vm.followingValues,
                    generation: _vm.generation,
                  },
                  on: { changed: _vm.changed },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }