"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPositive = void 0;
/** Validator that only allows positive numbers (`> 0`). */
const isPositive = (value) => {
    if (typeof value !== 'number' || value <= 0 || Number.isNaN(value)) {
        return 'value should be a positive number';
    }
    return undefined;
};
exports.isPositive = isPositive;
