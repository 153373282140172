var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "apos-tree__row-data apos-tree__header",
      class: _vm.headerClasses,
      attrs: { "aria-hidden": _vm.spacerOnly },
    },
    _vm._l(_vm.headers, function (col, index) {
      return _c(
        "span",
        {
          key: `${index}-${col.property}`,
          staticClass: "apos-tree__cell",
          class: `apos-tree--column-${col.property}`,
          style: _vm.getCellStyles(col),
          attrs: { "data-spacer": _vm.spacerOnly && col.property },
        },
        [
          col.columnHeaderIcon
            ? _c(_vm.icons[col.columnHeaderIcon], {
                tag: "component",
                staticClass: "apos-tree__cell__icon",
              })
            : _vm._e(),
          _vm._v("\n    " + _vm._s(_vm.$t(col.columnHeader)) + "\n  "),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }