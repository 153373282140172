var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AposInputWrapper", {
    attrs: {
      field: _vm.field,
      error: _vm.effectiveError,
      uid: _vm.uid,
      modifiers: _vm.modifiers,
      "display-options": _vm.displayOptions,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "apos-attachment" },
              [
                _c("AposFile", {
                  attrs: {
                    "allowed-extensions": _vm.field.accept,
                    uploading: _vm.uploading,
                    disabled: _vm.disabled,
                    attachment: _vm.next,
                    def: _vm.field.def,
                  },
                  on: { "upload-file": _vm.uploadMedia, update: _vm.updated },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }