var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AposInputWrapper", {
    attrs: {
      field: _vm.field,
      error: _vm.effectiveError,
      uid: _vm.uid,
      modifiers: _vm.modifiers,
      "display-options": _vm.displayOptions,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("AposSelect", {
              attrs: {
                choices: _vm.choices,
                disabled: _vm.field.readOnly,
                selected: _vm.value.data,
                id: _vm.uid,
                classes: ["apos-input__role"],
                "wrapper-classes": ["apos-input__role"],
              },
              on: { change: _vm.change },
            }),
            _vm._v(" "),
            _c("AposPermissionGrid", {
              attrs: { "api-params": { role: _vm.next } },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }