"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symbolProp = void 0;
const util_1 = require("../util");
const validators_1 = require("../validators");
/**
 * Allows any symbol (validated at runtime and compile time).
 *
 * @param validator - Optional function for further runtime validation; should return `undefined` if valid, or an error string if invalid.
 */
const symbolProp = (validator) => (0, util_1.propOptionsGenerator)(undefined, validator, validators_1.isSymbol);
exports.symbolProp = symbolProp;
