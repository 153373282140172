var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "apos-login__header",
      class: {
        "apos-login__header--tiny": _vm.tiny,
        "apos-login__header--center": !!_vm.subtitle,
      },
    },
    [
      _c("div", { staticClass: "apos-login__project-header" }, [
        _c(
          "label",
          {
            staticClass: "apos-login__project apos-login__project-env",
            class: [`apos-login__project-env--${_vm.env}`],
          },
          [_vm._v("\n      " + _vm._s(_vm.env) + "\n    ")]
        ),
        _vm._v(" "),
        _vm.subtitle
          ? _c(
              "label",
              {
                staticClass: "apos-login__project apos-login__project-subtitle",
              },
              [_vm._v("\n      " + _vm._s(_vm.subtitle) + "\n    ")]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "label",
        { staticClass: "apos-login__project apos-login__project-name" },
        [_vm._v("\n    " + _vm._s(_vm.title) + "\n  ")]
      ),
      _vm._v(" "),
      _vm.help
        ? _c("label", { staticClass: "apos-login--help" }, [
            _vm._v("\n    " + _vm._s(_vm.help) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("label", { staticClass: "apos-login--error" }, [
        _vm._v("\n    " + _vm._s(_vm.error) + "\n  "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }