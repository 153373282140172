var render = function render(_c, _vm) {
  return _c(
    "span",
    _vm._g(
      _vm._b(
        {
          staticClass: "material-design-icon minus-icon",
          class: [_vm.data.class, _vm.data.staticClass],
          attrs: {
            "aria-hidden": _vm.props.decorative,
            "aria-label": _vm.props.title,
            role: "img",
          },
        },
        "span",
        _vm.data.attrs,
        false
      ),
      _vm.listeners
    ),
    [
      _c(
        "svg",
        {
          staticClass: "material-design-icon__svg",
          attrs: {
            fill: _vm.props.fillColor,
            width: _vm.props.size,
            height: _vm.props.size,
            viewBox: "0 0 24 24",
          },
        },
        [
          _c("path", { attrs: { d: "M19,13H5V11H19V13Z" } }, [
            !_vm.props.decorative
              ? _c("title", [_vm._v(_vm._s(_vm.props.title))])
              : _vm._e(),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }