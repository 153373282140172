"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.numberProp = void 0;
const util_1 = require("../util");
/**
 * Allows any number (validated at runtime and compile time).
 *
 * Type parameter `T` can be used to restrict the type at compile time with a union type.
 *
 * @param validator - Optional function for further runtime validation; should return `undefined` if valid, or an error string if invalid.
 */
const numberProp = (validator) => (0, util_1.propOptionsGenerator)(Number, validator);
exports.numberProp = numberProp;
