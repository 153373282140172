var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AposModal", {
    staticClass: "apos-doc-editor",
    attrs: {
      modal: _vm.modal,
      "modal-title": _vm.$t("apostrophe:editImageRelationshipTitle"),
    },
    on: {
      inactive: function ($event) {
        _vm.modal.active = false
      },
      "show-modal": function ($event) {
        _vm.modal.showModal = true
      },
      esc: _vm.confirmAndCancel,
      "no-modal": function ($event) {
        return _vm.$emit("safe-close")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "secondaryControls",
        fn: function () {
          return [
            _c("AposButton", {
              attrs: { type: "default", label: "apostrophe:cancel" },
              on: { click: _vm.confirmAndCancel },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "primaryControls",
        fn: function () {
          return [
            _c("AposButton", {
              attrs: {
                type: "primary",
                label: "apostrophe:update",
                disabled: _vm.docFields.hasErrors,
              },
              on: { click: _vm.submit },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "leftRail",
        fn: function () {
          return [
            _c("AposModalRail", [
              _c("div", { staticClass: "apos-schema" }, [
                _c(
                  "div",
                  { staticClass: "apos-field" },
                  [
                    _c(
                      "label",
                      { staticClass: "apos-field__label" },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("apostrophe:aspectRatio")) +
                            "\n            "
                        ),
                        _vm.disableAspectRatio
                          ? _c("AposIndicator", {
                              staticClass: "apos-field__tooltip",
                              attrs: {
                                icon: "information-outline-icon",
                                "fill-color": "var(--a-primary)",
                                tooltip: _vm.$t(
                                  "apostrophe:aspectRatioWarning"
                                ),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("AposSelect", {
                      attrs: {
                        choices: _vm.aspectRatios,
                        disabled: _vm.disableAspectRatio,
                      },
                      on: { change: _vm.updateAspectRatio },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("label", { staticClass: "apos-field__label" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("apostrophe:cropAndSize")) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _vm.minSize[0] || _vm.minSize[1]
                  ? _c(
                      "div",
                      {
                        staticClass: "apos-field__min-size",
                        class: {
                          "apos-field__min-size--correcting":
                            _vm.correctingSizes,
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("apostrophe:minSize", {
                                width: _vm.minSize[0] || "???",
                                height: _vm.minSize[1] || "???",
                              })
                            ) +
                            "\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "apos-schema__aligned-fields" }, [
                  _c("div", { staticClass: "apos-field" }, [
                    _c(
                      "label",
                      {
                        staticClass:
                          "apos-field__label apos-field__label--aligned",
                      },
                      [_vm._v("\n              W\n            ")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      staticClass: "apos-input apos-input--text",
                      attrs: {
                        type: "number",
                        min: _vm.minWidth,
                        max: _vm.maxWidth,
                      },
                      domProps: { value: _vm.docFields.data.width },
                      on: { input: _vm.inputWidth, blur: _vm.blurInput },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "apos-field" }, [
                    _c(
                      "label",
                      {
                        staticClass:
                          "apos-field__label apos-field__label--aligned",
                      },
                      [_vm._v("\n              H\n            ")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      staticClass: "apos-input apos-input--text",
                      attrs: {
                        type: "number",
                        min: _vm.minHeight,
                        max: _vm.maxHeight,
                      },
                      domProps: { value: _vm.docFields.data.height },
                      on: { input: _vm.inputHeight, blur: _vm.blurInput },
                    }),
                  ]),
                ]),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "main",
        fn: function () {
          return [
            _c(
              "div",
              {
                ref: "cropperContainer",
                staticClass: "apos-image-cropper__container",
              },
              [
                _vm.containerHeight
                  ? _c("AposImageCropper", {
                      attrs: {
                        attachment: _vm.item.attachment,
                        "doc-fields": _vm.docFields,
                        "aspect-ratio": _vm.aspectRatio,
                        "min-size": _vm.minSize,
                        "container-height": _vm.containerHeight,
                      },
                      on: { change: _vm.updateDocFields },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }