"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.vueComponentProp = void 0;
const util_1 = require("../util");
/**
 * Allows any Vue component instance, name or options object. No built-in runtime validation is performed by default.
 *
 * @param validator - Optional function for further runtime validation; should return `undefined` if valid, or an error string if invalid.
 */
const vueComponentProp = (validator) => (0, util_1.propOptionsGenerator)([Object, String], validator);
exports.vueComponentProp = vueComponentProp;
