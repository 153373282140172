var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AposInputWrapper", {
    attrs: {
      field: _vm.field,
      error: _vm.effectiveError,
      uid: _vm.uid,
      modifiers: _vm.modifiers,
      "display-options": _vm.displayOptions,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("AposSelect", {
              attrs: {
                icon: _vm.icon,
                choices: _vm.choices,
                classes: _vm.classes,
                disabled: _vm.field.readOnly,
                selected: _vm.value.data,
                autocomplete: _vm.field.autocomplete,
              },
              on: { change: _vm.change },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }