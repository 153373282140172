var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    {
      staticClass: "apos-choice-label",
      class: { "apos-choice-label--disabled": _vm.field.readOnly },
      attrs: { for: _vm.id, tabindex: { "-1": _vm.field.hideLabel } },
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.checkProxy,
            expression: "checkProxy",
          },
        ],
        staticClass: "apos-sr-only apos-input--choice apos-input--checkbox",
        attrs: {
          type: "checkbox",
          name: _vm.field.name,
          id: _vm.id,
          "aria-label": _vm.choice.label || _vm.field.label,
          tabindex: _vm.tabindex,
          disabled: _vm.field.readOnly || _vm.choice.readOnly,
          "is-indeterminate":
            _vm.choice.indeterminate === true ? "true" : "false",
        },
        domProps: {
          value: _vm.choice.value,
          checked: Array.isArray(_vm.checkProxy)
            ? _vm._i(_vm.checkProxy, _vm.choice.value) > -1
            : _vm.checkProxy,
        },
        on: {
          change: [
            function ($event) {
              var $$a = _vm.checkProxy,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = _vm.choice.value,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.checkProxy = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.checkProxy = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.checkProxy = $$c
              }
            },
            _vm.update,
          ],
        },
      }),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "apos-input-indicator",
          attrs: { "aria-hidden": "true" },
        },
        [
          _vm.isChecked(_vm.checked)
            ? _c(
                `${
                  _vm.choice.indeterminate ? "minus-icon" : "check-bold-icon"
                }`,
                { tag: "component", attrs: { size: 10 } }
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.choice.label
        ? _c(
            "span",
            {
              staticClass: "apos-choice-label-text",
              class: { "apos-sr-only": _vm.field.hideLabel },
            },
            [_vm._v("\n    " + _vm._s(_vm.$t(_vm.choice.label)) + "\n  ")]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }