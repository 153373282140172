var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition-group",
    {
      staticClass: "apos-notifications",
      class: _vm.themeClass,
      attrs: { name: "list", tag: "div" },
    },
    _vm._l(_vm.notifications, function (notification) {
      return _c("AposNotification", {
        key: notification._id,
        attrs: { notification: notification },
        on: { close: _vm.dismiss },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }