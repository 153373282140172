var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AposContextMenu", {
    staticClass: "apos-admin-user",
    attrs: {
      button: _vm.button,
      menu: _vm.items,
      "menu-placement": "bottom-end",
    },
    on: { "item-clicked": _vm.emitEvent },
    scopedSlots: _vm._u([
      {
        key: "prebutton",
        fn: function () {
          return [
            _c("AposAvatar", {
              staticClass: "apos-admin-user__avatar",
              attrs: { user: _vm.user },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }