var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AposInputWrapper", {
    attrs: {
      modifiers: _vm.modifiers,
      field: _vm.field,
      error: _vm.effectiveError,
      uid: _vm.uid,
      "display-options": _vm.displayOptions,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "apos-input-wrapper" },
              [
                !_vm.field.required
                  ? _c("AposToggle", {
                      staticClass: "apos-toggle",
                      on: { toggle: _vm.toggle },
                      model: {
                        value: _vm.disabled,
                        callback: function ($$v) {
                          _vm.disabled = $$v
                        },
                        expression: "disabled",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.date,
                      expression: "date",
                    },
                  ],
                  staticClass: "apos-input apos-input--date",
                  class: { "apos-input--disabled": _vm.disabled },
                  attrs: { type: "date" },
                  domProps: { value: _vm.date },
                  on: {
                    change: _vm.setDateAndTime,
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.date = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "apos-input--label" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("apostrophe:at")) + "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.time,
                      expression: "time",
                    },
                  ],
                  staticClass: "apos-input apos-input--time",
                  class: { "apos-input--disabled": _vm.disabled },
                  attrs: { type: "time" },
                  domProps: { value: _vm.time },
                  on: {
                    change: _vm.setDateAndTime,
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.time = $event.target.value
                    },
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }