var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "apos-utility-operations" },
    [
      _vm._l(_vm.utilityOperations.buttons, function (button) {
        return _c("AposButton", {
          key: button.action,
          attrs: {
            type: "default",
            "icon-only": button.iconOnly,
            icon: button.icon || false,
            label: button.label,
          },
          on: {
            click: function ($event) {
              return _vm.handleUtilityOperation(button.action)
            },
          },
        })
      }),
      _vm._v(" "),
      _vm.utilityOperations.menu.length
        ? _c("AposContextMenu", {
            attrs: {
              button: _vm.utilityOperations.button,
              menu: _vm.utilityOperations.menu,
            },
            on: { "item-clicked": _vm.handleUtilityOperation },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }