var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "apos-pager-dots" },
    _vm._l(_vm.dots, function (i) {
      return _c("li", { key: i, staticClass: "apos-pager-dots__item" }, [
        _c(
          "button",
          {
            staticClass: "apos-pager-dots__button",
            class: { "apos-is-active": i === _vm.index },
            attrs: { role: "button" },
            on: {
              click: function ($event) {
                return _vm.click(i)
              },
            },
          },
          [
            _c("span", { staticClass: "apos-sr-only" }, [
              _vm._v("\n        Activate item " + _vm._s(i) + "\n      "),
            ]),
          ]
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }