var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AposModal", {
    staticClass: "apos-command-menu-shortcut",
    attrs: { modal: _vm.modal },
    on: {
      esc: _vm.close,
      "no-modal": function ($event) {
        return _vm.$emit("safe-close")
      },
      inactive: function ($event) {
        _vm.modal.active = false
      },
      "show-modal": function ($event) {
        _vm.modal.showModal = true
      },
    },
    scopedSlots: _vm._u([
      {
        key: "main",
        fn: function () {
          return [
            _c("AposModalBody", {
              scopedSlots: _vm._u([
                {
                  key: "bodyMain",
                  fn: function () {
                    return [
                      _c("header", { staticClass: "apos-modal__header" }, [
                        _c(
                          "div",
                          { staticClass: "apos-modal__header__main" },
                          [
                            _c("AposButton", {
                              attrs: {
                                type: "default",
                                title: _vm.$t("apostrophe:commandMenuEsc"),
                                "icon-only": true,
                                icon: "keyboard-esc",
                              },
                              on: { click: _vm.close },
                            }),
                            _vm._v(" "),
                            _c("h2", { staticClass: "apos-modal__heading" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t("apostrophe:commandMenuShortcut")
                                  ) +
                                  "\n            "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "section",
                        { staticClass: "apos-command-menu-shortcut-groups" },
                        _vm._l(_vm.groups, function (group, groupName) {
                          return _c(
                            "div",
                            {
                              key: groupName,
                              staticClass: "apos-command-menu-shortcut-group",
                            },
                            [
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "apos-command-menu-shortcut-group-title",
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.getLabel(group.label)) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(
                                group.commands,
                                function (command, commandName) {
                                  return _c(
                                    "div",
                                    {
                                      key: commandName,
                                      staticClass:
                                        "apos-command-menu-shortcut-command",
                                      attrs: {
                                        "aria-keyshortcuts": command.shortcut,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "apos-command-menu-shortcut-command-title",
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.getLabel(command.label)
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("AposCommandMenuKeyList", {
                                        attrs: { shortcut: command.shortcut },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        }),
                        0
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }