var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "apos-image-control" },
    [
      _c("AposButton", {
        class: { "apos-is-active": _vm.buttonActive },
        attrs: {
          type: "rich-text",
          label: _vm.tool.label,
          "icon-only": !!_vm.tool.icon,
          icon: _vm.tool.icon || false,
          "icon-size": _vm.tool.iconSize || 16,
          modifiers: ["no-border", "no-motion"],
          tooltip: {
            content: _vm.tool.label,
            placement: "top",
            delay: 650,
          },
        },
        on: { click: _vm.click },
      }),
      _vm._v(" "),
      _c("AposImageControlDialog", {
        attrs: {
          active: _vm.active,
          editor: _vm.editor,
          "has-selection": _vm.hasSelection,
        },
        on: { close: _vm.close },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }