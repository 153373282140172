var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AposModal", {
    staticClass: "apos-doc-editor",
    attrs: { modal: _vm.modal, "modal-title": _vm.modalTitle },
    on: {
      inactive: function ($event) {
        _vm.modal.active = false
      },
      "show-modal": function ($event) {
        _vm.modal.showModal = true
      },
      esc: _vm.confirmAndCancel,
      "no-modal": function ($event) {
        return _vm.$emit("safe-close")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "secondaryControls",
        fn: function () {
          return [
            _c("AposButton", {
              attrs: { type: "default", label: "apostrophe:cancel" },
              on: { click: _vm.confirmAndCancel },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "primaryControls",
        fn: function () {
          return [
            _c("AposButton", {
              attrs: {
                type: "primary",
                label: "apostrophe:save",
                disabled: !!_vm.errorCount,
                tooltip: _vm.errorTooltip,
              },
              on: { click: _vm.submit },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "leftRail",
        fn: function () {
          return [
            _c(
              "AposModalRail",
              [
                _vm.tabs.length
                  ? _c("AposModalTabs", {
                      key: _vm.tabKey,
                      attrs: {
                        current: _vm.currentTab,
                        tabs: _vm.tabs,
                        errors: _vm.fieldErrors,
                      },
                      on: { "select-tab": _vm.switchPane },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "main",
        fn: function () {
          return [
            _c("AposModalBody", {
              scopedSlots: _vm._u([
                {
                  key: "bodyMain",
                  fn: function () {
                    return [
                      _c("AposModalTabsBody", [
                        _c(
                          "div",
                          { staticClass: "apos-doc-editor__body" },
                          _vm._l(_vm.tabs, function (tab) {
                            return _c("AposSchema", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: tab.name === _vm.currentTab,
                                  expression: "tab.name === currentTab",
                                },
                              ],
                              key: tab.name,
                              ref: tab.name,
                              refInFor: true,
                              attrs: {
                                schema: _vm.groups[tab.name].schema,
                                "current-fields": _vm.groups[tab.name].fields,
                                value: _vm.docFields,
                                "trigger-validation": _vm.triggerValidation,
                              },
                              on: { input: _vm.updateDocFields },
                            })
                          }),
                          1
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }