var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "apos-modal__body",
      class: { "apos-modal__body--flex": _vm.hasSlot("footer") },
    },
    [
      _c("div", { staticClass: "apos-modal__body-inner" }, [
        _vm.hasSlot("bodyHeader")
          ? _c(
              "div",
              { staticClass: "apos-modal__body-header" },
              [_vm._t("bodyHeader")],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "apos-modal__body-main" },
          [_vm._t("bodyMain")],
          2
        ),
      ]),
      _vm._v(" "),
      _vm.hasSlot("footer")
        ? _c(
            "div",
            { staticClass: "apos-modal__body-footer" },
            [_vm._t("footer")],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }