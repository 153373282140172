"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSymbol = void 0;
/** Validator that only allows symbols. */
const isSymbol = (value) => {
    if (typeof value !== 'symbol') {
        return 'value should be a symbol';
    }
    return undefined;
};
exports.isSymbol = isSymbol;
