"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isInteger = void 0;
/** Validator that only allows integer numbers. */
const isInteger = (value) => {
    if (typeof value !== 'number' || !Number.isInteger(value)) {
        return 'value should be an integer';
    }
    return undefined;
};
exports.isInteger = isInteger;
