var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    {
      class: _vm.dropzoneClasses,
      attrs: { disabled: _vm.disabled },
      on: {
        drop: function ($event) {
          $event.preventDefault()
          return _vm.uploadMedia.apply(null, arguments)
        },
        dragover: function ($event) {
          $event.preventDefault()
        },
        dragenter: _vm.incrementDragover,
        dragleave: _vm.decrementDragover,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "apos-media-uploader__inner",
          attrs: { tabindex: "0" },
          on: { keydown: _vm.onUploadDragAndDropKeyDown },
        },
        [
          _c("AposCloudUploadIcon", {
            staticClass: "apos-media-uploader__icon",
          }),
          _vm._v(" "),
          _c("div", { staticClass: "apos-media-uploader__instructions" }, [
            _c("p", { staticClass: "apos-media-uploader__primary" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      _vm.dragover
                        ? "apostrophe:mediaUploadViaDrop"
                        : "apostrophe:dropMedia"
                    )
                  ) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            !_vm.dragover
              ? _c("p", { staticClass: "apos-media-uploader__secondary" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("apostrophe:mediaUploadViaExplorer")) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("input", {
        ref: "upload",
        staticClass: "apos-sr-only",
        attrs: {
          type: "file",
          accept: _vm.accept,
          multiple: "true",
          disabled: _vm.disabled,
          tabindex: "-1",
        },
        on: { input: _vm.uploadMedia },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }