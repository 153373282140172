var render = function render(_c, _vm) {
  return _c(
    "span",
    _vm._g(
      _vm._b(
        {
          staticClass: "material-design-icon format-strikethrough-variant-icon",
          class: [_vm.data.class, _vm.data.staticClass],
          attrs: {
            "aria-hidden": _vm.props.decorative,
            "aria-label": _vm.props.title,
            role: "img",
          },
        },
        "span",
        _vm.data.attrs,
        false
      ),
      _vm.listeners
    ),
    [
      _c(
        "svg",
        {
          staticClass: "material-design-icon__svg",
          attrs: {
            fill: _vm.props.fillColor,
            width: _vm.props.size,
            height: _vm.props.size,
            viewBox: "0 0 24 24",
          },
        },
        [
          _c(
            "path",
            {
              attrs: {
                d: "M23,12V14H18.61C19.61,16.14 19.56,22 12.38,22C4.05,22.05 4.37,15.5 4.37,15.5L8.34,15.55C8.37,18.92 11.5,18.92 12.12,18.88C12.76,18.83 15.15,18.84 15.34,16.5C15.42,15.41 14.32,14.58 13.12,14H1V12H23M19.41,7.89L15.43,7.86C15.43,7.86 15.6,5.09 12.15,5.08C8.7,5.06 9,7.28 9,7.56C9.04,7.84 9.34,9.22 12,9.88H5.71C5.71,9.88 2.22,3.15 10.74,2C19.45,0.8 19.43,7.91 19.41,7.89Z",
              },
            },
            [
              !_vm.props.decorative
                ? _c("title", [_vm._v(_vm._s(_vm.props.title))])
                : _vm._e(),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }