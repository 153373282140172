var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "apos-avatar", style: _vm.style, attrs: { alt: _vm.alt } },
    [_c("span", [_vm._v(_vm._s(_vm.initials))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }