var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "apos-tag-list" }, [
    _vm.tags && _vm.tags.length
      ? _c("div", { staticClass: "apos-tag-list__inner" }, [
          _c("h3", { staticClass: "apos-tag-list__title" }, [
            _vm._v(_vm._s(_vm.$t(_vm.title))),
          ]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "apos-tag-list__items" },
            _vm._l(_vm.tags, function (tag) {
              return _c("AposTagListItem", {
                key: tag.slug,
                attrs: { "active-tag": _vm.active, tag: tag },
                on: { click: _vm.toggleTag },
              })
            }),
            1
          ),
        ])
      : _c(
          "div",
          { staticClass: "apos-tag-list__empty" },
          [_c("AposEmptyState", { attrs: { "empty-state": _vm.emptyState } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }