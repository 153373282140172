"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNegative = void 0;
/** Validator that only allows negative numbers (`< 0`). */
const isNegative = (value) => {
    if (typeof value !== 'number' || value >= 0 || Number.isNaN(value)) {
        return 'value should be a negative number';
    }
    return undefined;
};
exports.isNegative = isNegative;
