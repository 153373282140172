var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition-group",
    {
      staticClass:
        "apos-admin-bar__control-set apos-admin-bar__control-set--context-controls",
      attrs: { tag: "div", name: "flip" },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "apos-tooltip",
              rawName: "v-apos-tooltip",
              value: _vm.undoTooltips.undo,
              expression: "undoTooltips.undo",
            },
          ],
          key: "undo",
        },
        [
          _c("AposButton", {
            staticClass: "apos-admin-bar__context-button",
            attrs: {
              disabled: !_vm.canUndo,
              type: "subtle",
              modifiers: ["small", "no-motion"],
              label: "apostrophe:undo",
              icon: "undo-icon",
              "icon-only": true,
            },
            on: { click: _vm.undo },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "apos-tooltip",
              rawName: "v-apos-tooltip",
              value: _vm.undoTooltips.redo,
              expression: "undoTooltips.redo",
            },
          ],
          key: "redo",
        },
        [
          _c("AposButton", {
            staticClass: "apos-admin-bar__context-button",
            attrs: {
              disabled: !_vm.canRedo,
              type: "subtle",
              modifiers: ["small", "no-motion"],
              label: "apostrophe:redo",
              icon: "redo-icon",
              "icon-only": true,
            },
            on: { click: _vm.redo },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("TheAposSavingIndicator", {
        key: "status",
        attrs: {
          retrying: _vm.retrying,
          editing: _vm.editing,
          saving: _vm.saving,
          saved: _vm.saved,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }