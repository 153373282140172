"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.propOptionsGenerator = void 0;
const validators_1 = require("./validators");
const propOptionsGenerator = (type, userValidator, ...typeValidators) => ({
    optional: {
        type,
        required: false,
        default: undefined,
        validator: (0, validators_1.vuePropValidator)(userValidator, ...typeValidators),
    },
    nullable: {
        type,
        required: false,
        default: null,
        validator: (0, validators_1.vuePropValidator)(userValidator, ...typeValidators),
    },
    withDefault: (defaultValue) => ({
        type,
        required: false,
        default: defaultValue,
        validator: (0, validators_1.vuePropValidator)(userValidator, ...typeValidators),
    }),
    required: {
        type,
        required: true,
        validator: (0, validators_1.vuePropValidator)(userValidator, ...typeValidators),
    },
});
exports.propOptionsGenerator = propOptionsGenerator;
