var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      class: ["apos-breadcrumb", _vm.classObj],
      attrs: { "aria-label": "breadcrumb" },
    },
    [
      _c(
        "ol",
        { staticClass: "apos-breadcrumb__items" },
        _vm._l(_vm.items, function (item, index) {
          return _c(
            "li",
            { key: index, class: `apos-breadcrumb__item ${_vm.modifier}` },
            [
              _c(
                item.target ? "button" : "span",
                {
                  tag: "component",
                  attrs: {
                    "data-apos-target": item.target,
                    type: item.target ? "button" : null,
                    "aria-label": item.target
                      ? {
                          key: "apostrophe:returnToPage",
                          label: _vm.$t(item.label),
                        }
                      : null,
                  },
                  on: {
                    click: function ($event) {
                      item.target ? _vm.$emit("return-to", item.target) : null
                    },
                  },
                },
                [_vm._v("\n        " + _vm._s(item.label) + "\n      ")]
              ),
              _vm._v(" "),
              index !== _vm.last
                ? _c("ChevronRightIcon", {
                    staticClass: "apos-breadcrumb__chevron",
                    attrs: { size: 13 },
                  })
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }