var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "apos-media-manager-selections" }, [
    _vm.items.length
      ? _c("div", [
          _c(
            "div",
            { staticClass: "apos-media-manager-selections__heading" },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.items.length) +
                  " items selected\n      "
              ),
              _c("AposButton", {
                attrs: {
                  label: "apostrophe:clear",
                  type: "quiet",
                  modifiers: ["no-motion"],
                },
                on: { click: _vm.clear },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ol",
            { staticClass: "apos-media-manager-selections__items" },
            _vm._l(_vm.items, function (item) {
              return _c(
                "li",
                {
                  key: item._id,
                  staticClass: "apos-media-manager-selections__item",
                },
                [
                  item.attachment && item.attachment._urls
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "apos-media-manager-selections__item-thumb-container",
                        },
                        [
                          _c("img", {
                            staticClass:
                              "apos-media-manager-selections__item-thumb",
                            attrs: {
                              src: item.attachment._urls["one-sixth"],
                              alt: item.description || item.title,
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "apos-media-manager-selections__item-info" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "apos-media-manager-selections__item-title",
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.title) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      item._edit
                        ? _c("AposButton", {
                            attrs: {
                              label: "apostrophe:edit",
                              type: "quiet",
                              modifiers: ["no-motion"],
                            },
                            on: {
                              click: function ($event) {
                                return _vm.edit(item._id)
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          ),
        ])
      : _c(
          "div",
          { staticClass: "apos-media-manager-selection__empty" },
          [_c("AposEmptyState", { attrs: { "empty-state": _vm.emptyState } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }