var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "apos-image-cropper",
      style: {
        height: _vm.cropperHeight,
      },
      on: { click: _vm.onImageClick },
    },
    [
      _c("span", {
        directives: [
          {
            name: "apos-tooltip",
            rawName: "v-apos-tooltip",
            value: "apostrophe:focalPoint",
            expression: "'apostrophe:focalPoint'",
          },
        ],
        ref: "focalPoint",
        staticClass: "apos-image-focal-point",
        on: { mousedown: _vm.onFocalPointMouseDown },
      }),
      _vm._v(" "),
      _c("cropper", {
        ref: "cropper",
        attrs: {
          src: _vm.attachment._urls.uncropped
            ? _vm.attachment._urls.uncropped.original
            : _vm.attachment._urls.original,
          debounce: 0,
          "default-size": _vm.defaultSize,
          "default-position": _vm.defaultPosition,
          "stencil-props": _vm.stencilProps,
          "min-width": _vm.minSize[0],
          "min-height": _vm.minSize[1],
        },
        on: { ready: _vm.onCropperReady, change: _vm.onCropperChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }