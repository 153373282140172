var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "apos-area-menu",
      class: { "apos-is-focused": _vm.groupIsFocused },
    },
    [
      _c(
        "AposContextMenu",
        _vm._b(
          {
            ref: "contextMenu",
            attrs: {
              disabled: _vm.isDisabled,
              button: _vm.buttonOptions,
              "popover-modifiers": _vm.inContext ? ["z-index-in-context"] : [],
            },
            on: { open: _vm.menuOpen, close: _vm.menuClose },
          },
          "AposContextMenu",
          _vm.extendedContextMenuOptions,
          false
        ),
        [
          _c(
            "ul",
            { staticClass: "apos-area-menu__wrapper" },
            _vm._l(_vm.myMenu, function (item, itemIndex) {
              return _c(
                "li",
                {
                  key: item.type ? `${item.type}_${item.label}` : item.label,
                  ref: `item-${itemIndex}`,
                  refInFor: true,
                  staticClass: "apos-area-menu__item",
                  class: { "apos-has-group": item.items },
                },
                [
                  item.items
                    ? _c("dl", { staticClass: "apos-area-menu__group" }, [
                        _c("dt", [
                          item.items
                            ? _c(
                                "button",
                                {
                                  ref: "groupButton",
                                  refInFor: true,
                                  staticClass: "apos-area-menu__group-label",
                                  attrs: {
                                    for: item.label,
                                    tabindex: "0",
                                    id: `${_vm.menuId}-trigger-${itemIndex}`,
                                    "aria-controls": `${_vm.menuId}-group-${itemIndex}`,
                                  },
                                  on: {
                                    focus: _vm.groupFocused,
                                    blur: _vm.groupBlurred,
                                    click: function ($event) {
                                      return _vm.toggleGroup(itemIndex)
                                    },
                                    keydown: [
                                      function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "space",
                                            32,
                                            $event.key,
                                            [" ", "Spacebar"]
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                        return _vm.toggleGroup(itemIndex)
                                      },
                                      function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                        return _vm.toggleGroup(itemIndex)
                                      },
                                      function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "arrow-down",
                                            undefined,
                                            $event.key,
                                            undefined
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                        return _vm.switchGroup(itemIndex, 1)
                                      },
                                      function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "arrow-up",
                                            undefined,
                                            $event.key,
                                            undefined
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                        return _vm.switchGroup(itemIndex, -1)
                                      },
                                      function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "home",
                                            undefined,
                                            $event.key,
                                            undefined
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                        return _vm.switchGroup(itemIndex, 0)
                                      },
                                      function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "end",
                                            undefined,
                                            $event.key,
                                            undefined
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                        return _vm.switchGroup(itemIndex, null)
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("span", [_vm._v(_vm._s(item.label))]),
                                  _vm._v(" "),
                                  _c("chevron-up-icon", {
                                    staticClass:
                                      "apos-area-menu__group-chevron",
                                    class: {
                                      "apos-is-active":
                                        itemIndex === _vm.active,
                                    },
                                    attrs: { size: 13 },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c(
                          "dd",
                          {
                            staticClass: "apos-area-menu__group-list",
                            attrs: { role: "region" },
                          },
                          [
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "apos-area-menu__items apos-area-menu__items--accordion",
                                class: {
                                  "apos-is-active": _vm.active === itemIndex,
                                },
                                attrs: {
                                  id: `${_vm.menuId}-group-${itemIndex}`,
                                  "aria-labelledby": `${_vm.menuId}-trigger-${itemIndex}`,
                                  "aria-expanded":
                                    _vm.active === itemIndex ? "true" : "false",
                                },
                              },
                              _vm._l(item.items, function (child, childIndex) {
                                return _c(
                                  "li",
                                  {
                                    key: child.name,
                                    ref: `child-${_vm.index}-${childIndex}`,
                                    refInFor: true,
                                    staticClass: "apos-area-menu__item",
                                  },
                                  [
                                    _c("AposAreaMenuItem", {
                                      attrs: {
                                        item: child,
                                        tabbable: itemIndex === _vm.active,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.add(child)
                                        },
                                        up: function ($event) {
                                          return _vm.switchItem(
                                            `child-${itemIndex}-${
                                              childIndex - 1
                                            }`,
                                            -1
                                          )
                                        },
                                        down: function ($event) {
                                          return _vm.switchItem(
                                            `child-${itemIndex}-${
                                              childIndex + 1
                                            }`,
                                            1
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ])
                    : _c("AposAreaMenuItem", {
                        attrs: { item: item },
                        on: {
                          click: function ($event) {
                            return _vm.add(item)
                          },
                          up: function ($event) {
                            return _vm.switchItem(`item-${itemIndex - 1}`, -1)
                          },
                          down: function ($event) {
                            return _vm.switchItem(`item-${itemIndex + 1}`, 1)
                          },
                        },
                      }),
                ],
                1
              )
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }