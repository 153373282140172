"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.oneOfObjectKeysProp = void 0;
const oneOf_1 = require("./oneOf");
/**
 * Allows any of the keys of the specified object (validated at runtime and compile time).
 *
 * Type parameter `T` can be used to adjust the inferred type at compile time, this is usually not necessary.
 *
 * @param object - The object whose keys are allowed.
 * @param validator - Optional function for further runtime validation; should return `undefined` if valid, or an error string if invalid.
 */
const oneOfObjectKeysProp = (object, validator) => (0, oneOf_1.oneOfProp)(Object.keys(object), validator);
exports.oneOfObjectKeysProp = oneOfObjectKeysProp;
