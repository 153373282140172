var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "apos-table__cell-field apos-table__cell-field--labels",
      class: `apos-table__cell-field--${_vm.header.name}`,
    },
    [
      _vm.manuallyPublished && _vm.draft.modified && _vm.draft.lastPublishedAt
        ? _c(
            "span",
            [
              _c("AposLabel", {
                staticClass: "apos-table__cell-field__label",
                attrs: {
                  label: "apostrophe:pendingUpdates",
                  tooltip: "apostrophe:thereAreActiveChanges",
                  modifiers: ["apos-is-success", "apos-is-filled"],
                },
              }),
            ],
            1
          )
        : _vm.draft.submitted
        ? _c(
            "span",
            [
              _c("AposLabel", {
                staticClass: "apos-table__cell-field__label",
                attrs: {
                  label: "apostrophe:pending",
                  tooltip: "apostrophe:changesAwaitingApproval",
                  modifiers: ["apos-is-filled"],
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.manuallyPublished && !_vm.draft.lastPublishedAt
        ? _c(
            "span",
            [
              _c("AposLabel", {
                staticClass: "apos-table__cell-field__label",
                attrs: {
                  label: "apostrophe:draft",
                  modifiers: ["apos-is-warning", "apos-is-filled"],
                  tooltip: "apostrophe:notYetPublished",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.draft.archived
        ? _c(
            "span",
            [
              _c("AposLabel", {
                staticClass: "apos-table__cell-field__label",
                attrs: {
                  label: "apostrophe:archived",
                  modifiers: ["apos-is-filled", "apos-is-danger"],
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }