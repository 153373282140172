var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AposInputWrapper", {
    attrs: {
      modifiers: _vm.modifiers,
      field: _vm.field,
      error: _vm.effectiveError,
      uid: _vm.uid,
      "display-options": _vm.displayOptions,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { staticClass: "apos-input-wrapper" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "apos-tooltip",
                      rawName: "v-apos-tooltip",
                      value: _vm.tooltip,
                      expression: "tooltip",
                    },
                  ],
                  staticClass: "apos-range",
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.next,
                        expression: "next",
                      },
                    ],
                    staticClass: "apos-range__input",
                    attrs: {
                      type: "range",
                      min: _vm.field.min,
                      max: _vm.field.max,
                      step: _vm.field.step,
                      id: _vm.uid,
                      disabled: _vm.field.readOnly,
                    },
                    domProps: { value: _vm.next },
                    on: {
                      __r: function ($event) {
                        _vm.next = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "apos-range__scale" }, [
                    _c("span", [
                      _c("span", { staticClass: "apos-sr-only" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("apostrophe:minLabel")) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(
                        "\n            " + _vm._s(_vm.minLabel) + "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _c("span", { staticClass: "apos-sr-only" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("apostrophe:maxLabel")) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(
                        "\n            " + _vm._s(_vm.maxLabel) + "\n          "
                      ),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "apos-range__value",
                  class: { "apos-is-unset": !_vm.isSet },
                  attrs: { "aria-hidden": "true" },
                },
                [
                  _vm._v("\n        " + _vm._s(_vm.valueLabel) + "\n        "),
                  _c("AposButton", {
                    staticClass: "apos-range__clear",
                    attrs: {
                      type: "quiet",
                      label: "apostrophe:clear",
                      modifiers: ["no-motion"],
                    },
                    on: { click: _vm.unset },
                  }),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }