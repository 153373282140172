var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AposInputWrapper", {
    attrs: {
      modifiers: _vm.modifiers,
      field: _vm.field,
      error: _vm.effectiveError,
      uid: _vm.uid,
      "display-options": _vm.displayOptions,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { staticClass: "apos-color" }, [
              _c(
                "div",
                { staticClass: "apos-color__ui" },
                [
                  _c(
                    "AposContextMenu",
                    {
                      attrs: {
                        button: _vm.buttonOptions,
                        "menu-placement": "bottom-start",
                        "menu-offset": "5, 20",
                        disabled: _vm.field.readOnly,
                        tooltip: _vm.tooltip,
                      },
                      on: { open: _vm.open, close: _vm.close },
                    },
                    [
                      _c(
                        "Picker",
                        _vm._b(
                          {
                            attrs: { value: _vm.next },
                            on: { input: _vm.update },
                          },
                          "Picker",
                          _vm.pickerOptions,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "apos-color__info" },
                [
                  _vm._v("\n        " + _vm._s(_vm.valueLabel) + "\n        "),
                  _vm.next
                    ? _c("AposButton", {
                        staticClass: "apos-color__clear",
                        attrs: {
                          type: "quiet",
                          label: "apostrophe:clear",
                          modifiers: ["no-motion"],
                        },
                        on: { click: _vm.clear },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }