var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.options.expanded
    ? _c(
        "AposButton",
        _vm._b(
          {
            attrs: { disabled: _vm.disabled, role: "button" },
            on: {
              click: function ($event) {
                return _vm.openExpandedMenu(_vm.index)
              },
            },
          },
          "AposButton",
          _vm.buttonOptions,
          false
        )
      )
    : _c("AposAreaContextualMenu", {
        attrs: {
          "button-options": _vm.buttonOptions,
          "context-menu-options": _vm.contextMenuOptions,
          empty: true,
          index: _vm.index,
          "widget-options": _vm.options.widgets,
          options: _vm.options,
          "max-reached": _vm.maxReached,
          disabled: _vm.disabled,
        },
        on: {
          add: function ($event) {
            return _vm.$emit("add", $event)
          },
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }