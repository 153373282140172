var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AposButton", {
    attrs: {
      icon: "tray-full-icon",
      type: "subtle",
      modifiers: ["small", "no-motion"],
      disabled: _vm.count <= 0,
      tooltip: _vm.tooltip,
      "icon-only": true,
    },
    on: { click: _vm.open },
    scopedSlots: _vm._u([
      {
        key: "label",
        fn: function () {
          return [
            _vm.canPublish && _vm.count > 0
              ? _c("span", { staticClass: "apos-submitted-drafts__counter" }, [
                  _vm._v("\n      " + _vm._s(_vm.count) + "\n    "),
                ])
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }