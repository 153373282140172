var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "apos-toolbar", class: _vm.className }, [
    _c(
      "div",
      { staticClass: "apos-toolbar__group apos-toolbar__group--left" },
      [_vm._t("leftControls")],
      2
    ),
    _vm._v(" "),
    _vm.hasRightControls
      ? _c(
          "div",
          { staticClass: "apos-toolbar__group apos-toolbar__group--right" },
          [_vm._t("rightControls")],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }