var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.classList, attrs: { role: "alert" } }, [
    _c(
      "span",
      { staticClass: "apos-notification__indicator" },
      [
        _c("AposIndicator", {
          staticClass: "apos-notification__indicator__icon",
          attrs: {
            icon: _vm.iconComponent,
            "icon-size": _vm.notification.icon ? 16 : 12,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "span",
      { ref: "label", staticClass: "apos-notification__label" },
      [
        _vm._v(
          "\n    " + _vm._s(_vm.localize(_vm.notification.message)) + "\n    "
        ),
        _vm._v(" "),
        _vm._l(_vm.notification.buttons, function (button, i) {
          return _c(
            "button",
            {
              key: i,
              attrs: {
                "data-apos-bus-event": JSON.stringify({
                  name: button.name,
                  data: button.data,
                }),
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.localize(button.label)) + "\n    ")]
          )
        }),
      ],
      2
    ),
    _vm._v(" "),
    _vm.job && _vm.job.total
      ? _c("div", { staticClass: "apos-notification__progress" }, [
          _c("div", { staticClass: "apos-notification__progress-bar" }, [
            _c("div", {
              staticClass: "apos-notification__progress-now",
              style: `width: ${_vm.job.percentage + "%"}`,
              attrs: {
                role: "progressbar",
                "aria-valuenow": _vm.job.processed || 0,
                "aria-valuemin": "0",
                "aria-valuemax": _vm.job.total,
              },
            }),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "apos-notification__progress-value" }, [
            _vm._v(
              "\n      " +
                _vm._s(Math.floor(_vm.job.percentage) + "%") +
                "\n    "
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.job
      ? _c(
          "button",
          {
            staticClass: "apos-notification__button",
            on: { click: _vm.close },
          },
          [
            _c("Close", {
              staticClass: "apos-notification__close-icon",
              attrs: { title: "Close Notification", size: 14 },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }