var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade-stage" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loaded,
            expression: "loaded",
          },
        ],
        staticClass: "apos-login apos-theme-dark",
        class: _vm.themeClass,
        attrs: { "data-apos-test": "loginForm" },
      },
      [
        _c("transition", { attrs: { name: "fade-outer" } }, [
          _vm.showNav
            ? _c("div", { staticClass: "apos-login__nav" }, [
                _c(
                  "a",
                  {
                    staticClass: "apos-login__link apos-login--arrow-left",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.setStage("login")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("apostrophe:loginBack")))]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "apos-login__link apos-login--arrow-right",
                    attrs: { href: _vm.homeUrl },
                  },
                  [_vm._v(_vm._s(_vm.$t("apostrophe:loginHome")))]
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "apos-login__wrapper" },
          [
            _c(
              "transition",
              { attrs: { name: "fade-body", mode: "out-in" } },
              [
                _vm.loaded && _vm.stage === "forgotPassword"
                  ? _c("AposForgotPasswordForm", {
                      attrs: {
                        context: _vm.context,
                        "context-error": _vm.error,
                      },
                      on: {
                        redirect: _vm.onRedirect,
                        "set-stage": _vm.setStage,
                      },
                    })
                  : _vm.loaded && _vm.stage === "resetPassword"
                  ? _c("AposResetPasswordForm", {
                      attrs: {
                        context: _vm.context,
                        data: _vm.passwordResetData,
                        "context-error": _vm.error,
                      },
                      on: {
                        redirect: _vm.onRedirect,
                        "set-stage": _vm.setStage,
                      },
                    })
                  : _vm.loaded
                  ? _c("AposLoginForm", {
                      attrs: {
                        context: _vm.context,
                        "context-error": _vm.error,
                      },
                      on: {
                        redirect: _vm.onRedirect,
                        "set-stage": _vm.setStage,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("transition", { attrs: { name: "fade-outer" } }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loaded,
                  expression: "loaded",
                },
              ],
              staticClass: "apos-login__footer",
            },
            [
              _c("AposLogo", { staticClass: "apos-login__logo" }),
              _vm._v(" "),
              _c("label", { staticClass: "apos-login__project-version" }, [
                _vm._v(
                  "\n          Version " +
                    _vm._s(_vm.context.version) +
                    "\n        "
                ),
              ]),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }