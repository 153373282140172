var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "apos-min-max-count" }, [
    _vm.countLabel
      ? _c("span", { staticClass: "apos-min-max-count__label" }, [
          _vm._v("\n    " + _vm._s(_vm.countLabel) + "\n  "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.minLabel
      ? _c(
          "span",
          {
            staticClass: "apos-min-max-count__label",
            class: _vm.minError ? "apos-has-error" : "",
          },
          [_vm._v("\n    " + _vm._s(_vm.minLabel) + "\n  ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.maxLabel
      ? _c(
          "span",
          {
            staticClass: "apos-min-max-count__label",
            class: _vm.maxError ? "apos-has-error" : "",
          },
          [_vm._v("\n    " + _vm._s(_vm.maxLabel) + "\n  ")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }