var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { class: _vm.classes },
    [
      _vm.icon
        ? _c("AposIndicator", {
            staticClass: "apos-button__icon",
            attrs: {
              icon: _vm.icon,
              "icon-size": _vm.iconSize,
              "fill-color": "color",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.label
        ? _vm._t("label", function () {
            return [_vm._v("\n    " + _vm._s(_vm.$t(_vm.label)) + "\n  ")]
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }