var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "apos-tiptap-select" },
    [
      _c("format-text-icon", {
        staticClass: "apos-tiptap-select__type-icon",
        attrs: { size: 16, "fill-color": "currentColor" },
      }),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "apos-tooltip",
              rawName: "v-apos-tooltip",
              value: {
                content: "apostrophe:richTextStyles",
                placement: "top",
                delay: 650,
              },
              expression:
                "{\n      content: 'apostrophe:richTextStyles',\n      placement: 'top',\n      delay: 650\n    }",
            },
          ],
          staticClass: "apos-tiptap-control apos-tiptap-control--select",
          style: `width:${_vm.options.styles[_vm.active].label.length * 6.5}px`,
          domProps: { value: _vm.active },
          on: { change: _vm.setStyle },
        },
        _vm._l(_vm.options.styles, function (style, i) {
          return _c("option", { key: style.label, domProps: { value: i } }, [
            _vm._v("\n      " + _vm._s(style.label) + "\n    "),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("chevron-down-icon", {
        staticClass: "apos-tiptap-select__icon",
        attrs: { size: 11, "fill-color": "currentColor" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }