"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.arrayProp = void 0;
const util_1 = require("../util");
/**
 * Allows any array. No further runtime validation is performed by default.
 *
 * Type parameter `T` can be used to restrict the type of the array items at compile time.
 *
 *  @param validator - Optional function for further runtime validation; should return `undefined` if valid, or an error string if invalid.
 */
const arrayProp = (validator) => (0, util_1.propOptionsGenerator)(Array, validator);
exports.arrayProp = arrayProp;
