var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AposModal", {
    attrs: { modal: _vm.modal, "modal-title": "apostrophe:managePages" },
    on: {
      esc: _vm.confirmAndCancel,
      "no-modal": function ($event) {
        return _vm.$emit("safe-close")
      },
      inactive: function ($event) {
        _vm.modal.active = false
      },
      "show-modal": function ($event) {
        _vm.modal.showModal = true
      },
    },
    scopedSlots: _vm._u(
      [
        {
          key: "secondaryControls",
          fn: function () {
            return [
              _vm.relationshipField
                ? _c("AposButton", {
                    attrs: { type: "default", label: "apostrophe:cancel" },
                    on: { click: _vm.confirmAndCancel },
                  })
                : _c("AposButton", {
                    attrs: { type: "default", label: "apostrophe:exit" },
                    on: { click: _vm.confirmAndCancel },
                  }),
            ]
          },
          proxy: true,
        },
        {
          key: "primaryControls",
          fn: function () {
            return [
              _c("AposUtilityOperations", {
                attrs: {
                  "module-options": _vm.moduleOptions,
                  "has-relationship-field": !!_vm.relationshipField,
                },
              }),
              _vm._v(" "),
              _vm.relationshipField
                ? _c("AposContextMenu", {
                    attrs: {
                      menu: _vm.moreMenu,
                      "menu-placement": "bottom-end",
                      button: _vm.moreMenuButton,
                    },
                    on: { "item-clicked": _vm.moreMenuHandler },
                  })
                : _vm.canCreate
                ? _c("AposButton", {
                    attrs: { type: "primary", label: "apostrophe:newPage" },
                    on: {
                      click: function ($event) {
                        return _vm.create()
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.relationshipField
                ? _c("AposButton", {
                    attrs: {
                      type: "primary",
                      label: _vm.saveRelationshipLabel,
                      disabled: !!_vm.relationshipErrors,
                    },
                    on: { click: _vm.saveRelationship },
                  })
                : _vm._e(),
            ]
          },
          proxy: true,
        },
        _vm.relationshipField
          ? {
              key: "leftRail",
              fn: function () {
                return [
                  _c("AposModalRail", [
                    _c(
                      "div",
                      { staticClass: "apos-pages-manager__relationship__rail" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "apos-pages-manager__relationship__counts",
                          },
                          [
                            _c("AposMinMaxCount", {
                              attrs: {
                                field: _vm.relationshipField,
                                value: _vm.checkedDocs,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("AposSlatList", {
                          staticClass:
                            "apos-pages-manager__relationship__items",
                          attrs: { value: _vm.checkedDocs },
                          on: { input: _vm.setCheckedDocs },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            }
          : null,
        {
          key: "main",
          fn: function () {
            return [
              _c("AposModalBody", {
                scopedSlots: _vm._u(
                  [
                    !_vm.relationshipField
                      ? {
                          key: "bodyHeader",
                          fn: function () {
                            return [
                              _c("AposModalToolbar", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "rightControls",
                                      fn: function () {
                                        return [
                                          _c("AposContextMenu", {
                                            attrs: {
                                              menu: _vm.pageSetMenu,
                                              "menu-placement": "bottom-end",
                                              button: _vm.pageSetMenuButton,
                                            },
                                            on: {
                                              "item-clicked": function (
                                                $event
                                              ) {
                                                _vm.pageSetMenuSelection =
                                                  $event
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  718101374
                                ),
                              }),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                    {
                      key: "bodyMain",
                      fn: function () {
                        return [
                          _c("AposTree", {
                            attrs: {
                              items: _vm.items,
                              headers: _vm.headers,
                              icons: _vm.icons,
                              options: _vm.treeOptions,
                              "module-options": _vm.moduleOptions,
                            },
                            on: { update: _vm.update },
                            model: {
                              value: _vm.checked,
                              callback: function ($$v) {
                                _vm.checked = $$v
                              },
                              expression: "checked",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              }),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }