var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "apos-confirm__notifications" },
    _vm._l(_vm.notifications, function (item, index) {
      return _c(
        "li",
        { key: index, staticClass: "apos-confirm__notification" },
        [
          _c("div", { class: _vm.className(item.type) }, [
            _c("span", { staticClass: "apos-confirm__notification-locale" }, [
              _vm._v("\n        " + _vm._s(item.locale.label) + "\n      "),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "apos-confirm__notification-meta" }, [
              _c(
                "span",
                { staticClass: "apos-confirm__notification-title" },
                [
                  !_vm.isError(item.type)
                    ? _c("CheckIcon", {
                        staticClass: "apos-check",
                        attrs: { size: 12 },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isError(item.type)
                    ? _c("CancelIcon", {
                        staticClass: "apos-error",
                        attrs: { size: 12 },
                      })
                    : _vm._e(),
                  _vm._v(
                    "\n          " + _vm._s(item.doc.title) + "\n        "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "apos-confirm__notification-item-type" },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.docType(item.doc)) +
                      "\n        "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            item.detail
              ? _c(
                  "div",
                  { staticClass: "apos-confirm__notification-detail" },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t(item.detail)) + "\n      "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }