var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    { staticClass: "apos-pager" },
    [
      _c("AposButton", {
        staticClass: "apos-pager__btn",
        attrs: {
          disabled: _vm.currentPage == 1,
          modifiers: ["small"],
          type: "outline",
          "icon-only": true,
          icon: "chevron-left-icon",
          label: _vm.prevButtonLabel,
        },
        on: {
          click: function ($event) {
            return _vm.incrementPage(-1)
          },
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "apos-input-wrapper" },
        [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedPage,
                  expression: "selectedPage",
                },
              ],
              staticClass:
                "apos-pager__page-select apos-input apos-input--select",
              attrs: {
                disabled: _vm.totalPages <= 1,
                "aria-label": _vm.$t("apostrophe:selectPage"),
              },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.selectedPage = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            _vm._l(_vm.totalPages, function (num) {
              return _c("option", { key: num, domProps: { value: num } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("apostrophe:pageNumber", { number: num })) +
                    "\n      "
                ),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c("menu-swap-icon", {
            staticClass: "apos-input-icon",
            attrs: { size: 18 },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("AposButton", {
        staticClass: "apos-pager__btn",
        attrs: {
          disabled: _vm.currentPage >= _vm.totalPages,
          modifiers: ["small"],
          type: "outline",
          "icon-only": true,
          icon: "chevron-right-icon",
          label: _vm.nextButtonLabel,
        },
        on: {
          click: function ($event) {
            return _vm.incrementPage(1)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }