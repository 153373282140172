var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AposModal", {
    ref: "modal",
    attrs: { modal: _vm.modal, "modal-title": _vm.modalTitle },
    on: {
      esc: _vm.confirmAndCancel,
      "no-modal": function ($event) {
        return _vm.$emit("safe-close")
      },
      inactive: function ($event) {
        _vm.modal.active = false
      },
      "show-modal": function ($event) {
        _vm.modal.showModal = true
      },
    },
    scopedSlots: _vm._u(
      [
        {
          key: "secondaryControls",
          fn: function () {
            return [
              _vm.relationshipField
                ? _c("AposButton", {
                    attrs: { type: "default", label: "apostrophe:cancel" },
                    on: { click: _vm.confirmAndCancel },
                  })
                : _c("AposButton", {
                    attrs: { type: "default", label: "apostrophe:exit" },
                    on: { click: _vm.confirmAndCancel },
                  }),
            ]
          },
          proxy: true,
        },
        {
          key: "primaryControls",
          fn: function () {
            return [
              _c("AposUtilityOperations", {
                attrs: {
                  "module-options": _vm.moduleOptions,
                  "has-relationship-field": !!_vm.relationshipField,
                },
              }),
              _vm._v(" "),
              _vm.relationshipField
                ? _c("AposButton", {
                    attrs: {
                      type: "primary",
                      label: _vm.saveRelationshipLabel,
                      disabled: !!_vm.relationshipErrors,
                    },
                    on: { click: _vm.saveRelationship },
                  })
                : _vm.moduleOptions.canCreate && _vm.moduleOptions.showCreate
                ? _c("AposButton", {
                    attrs: {
                      label: {
                        key: "apostrophe:newDocType",
                        type: _vm.$t(_vm.moduleOptions.label),
                      },
                      type: "primary",
                    },
                    on: { click: _vm.create },
                  })
                : _vm._e(),
            ]
          },
          proxy: true,
        },
        _vm.relationshipField
          ? {
              key: "leftRail",
              fn: function () {
                return [
                  _c("AposModalRail", [
                    _c(
                      "div",
                      {
                        staticClass: "apos-pieces-manager__relationship__rail",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "apos-pieces-manager__relationship__counts",
                          },
                          [
                            _c("AposMinMaxCount", {
                              attrs: {
                                field: _vm.relationshipField,
                                value: _vm.checkedDocs,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("AposSlatList", {
                          staticClass:
                            "apos-pieces-manager__relationship__items",
                          attrs: {
                            value: _vm.checkedDocs,
                            "relationship-schema":
                              _vm.relationshipField?.schema,
                          },
                          on: {
                            input: _vm.setCheckedDocs,
                            "item-clicked": _vm.editRelationship,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            }
          : null,
        {
          key: "main",
          fn: function () {
            return [
              _c("AposModalBody", {
                scopedSlots: _vm._u([
                  {
                    key: "bodyHeader",
                    fn: function () {
                      return [
                        _c("AposDocsManagerToolbar", {
                          attrs: {
                            "selected-state": _vm.selectAllState,
                            "total-pages": _vm.totalPages,
                            "current-page": _vm.currentPage,
                            "filter-choices": _vm.filterChoices,
                            "filter-values": _vm.filterValues,
                            filters: _vm.moduleOptions.filters,
                            labels: _vm.moduleLabels,
                            "displayed-items": _vm.items.length,
                            "is-relationship": !!_vm.relationshipField,
                            checked: _vm.checked,
                            "checked-count": _vm.checked.length,
                            "batch-operations":
                              _vm.moduleOptions.batchOperations,
                            "module-name": _vm.moduleName,
                            options: {
                              disableUnchecked: _vm.maxReached(),
                            },
                          },
                          on: {
                            "select-click": _vm.selectAll,
                            search: _vm.onSearch,
                            "page-change": _vm.updatePage,
                            filter: _vm.filter,
                            batch: _vm.handleBatchAction,
                          },
                        }),
                        _vm._v(" "),
                        _c("AposDocsManagerSelectBox", {
                          attrs: {
                            "selected-state": _vm.selectAllState,
                            "module-labels": _vm.moduleLabels,
                            "filter-values": _vm.filterValues,
                            "checked-ids": _vm.checked,
                            "all-pieces-selection": _vm.allPiecesSelection,
                            "displayed-items": _vm.items.length,
                          },
                          on: {
                            "select-all": _vm.selectAllPieces,
                            "set-all-pieces-selection":
                              _vm.setAllPiecesSelection,
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "bodyMain",
                    fn: function () {
                      return [
                        _vm.items.length > 0
                          ? _c("AposDocsManagerDisplay", {
                              attrs: {
                                items: _vm.items,
                                headers: _vm.headers,
                                options: {
                                  ..._vm.moduleOptions,
                                  disableUnchecked: _vm.maxReached(),
                                  disableUnpublished: _vm.disableUnpublished,
                                  manuallyPublished: _vm.manuallyPublished,
                                },
                              },
                              on: { open: _vm.edit },
                              model: {
                                value: _vm.checked,
                                callback: function ($$v) {
                                  _vm.checked = $$v
                                },
                                expression: "checked",
                              },
                            })
                          : _c(
                              "div",
                              { staticClass: "apos-pieces-manager__empty" },
                              [
                                _c("AposEmptyState", {
                                  attrs: { "empty-state": _vm.emptyDisplay },
                                }),
                              ],
                              1
                            ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }