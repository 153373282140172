var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "lip", staticClass: "apos-modal-lip" }, [
    _c("div", { staticClass: "apos-modal-lip__shadow" }, [
      _c(
        "svg",
        {
          attrs: {
            width: "406px",
            height: "56px",
            viewBox: "0 0 406 56",
            version: "1.1",
            xmlns: "http://www.w3.org/2000/svg",
            "xmlns:xlink": "http://www.w3.org/1999/xlink",
          },
        },
        [
          _c("title", [_vm._v("Shadow")]),
          _vm._v(" "),
          _c("defs", [
            _c("rect", {
              attrs: {
                id: "shadow-path-1",
                x: "0",
                y: "0",
                width: "406",
                height: "56",
              },
            }),
            _vm._v(" "),
            _c("rect", {
              attrs: {
                id: "shadow-path-3",
                x: "-13",
                y: "20",
                width: "432",
                height: "83",
              },
            }),
            _vm._v(" "),
            _c(
              "filter",
              {
                attrs: {
                  x: "-6.2%",
                  y: "-28.9%",
                  width: "112.5%",
                  height: "165.1%",
                  filterUnits: "objectBoundingBox",
                  id: "shadow-filter-4",
                },
              },
              [
                _c("feMorphology", {
                  attrs: {
                    radius: "3",
                    operator: "dilate",
                    in: "SourceAlpha",
                    result: "shadowSpreadOuter1",
                  },
                }),
                _vm._v(" "),
                _c("feOffset", {
                  attrs: {
                    dx: "0",
                    dy: "0",
                    in: "shadowSpreadOuter1",
                    result: "shadowOffsetOuter1",
                  },
                }),
                _vm._v(" "),
                _c("feGaussianBlur", {
                  attrs: {
                    stdDeviation: "6.5",
                    in: "shadowOffsetOuter1",
                    result: "shadowBlurOuter1",
                  },
                }),
                _vm._v(" "),
                _c("feComposite", {
                  attrs: {
                    in: "shadowBlurOuter1",
                    in2: "SourceAlpha",
                    operator: "out",
                    result: "shadowBlurOuter1",
                  },
                }),
                _vm._v(" "),
                _c("feColorMatrix", {
                  attrs: {
                    values: _vm.shadow,
                    type: "matrix",
                    in: "shadowBlurOuter1",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "g",
            {
              attrs: {
                stroke: "none",
                "stroke-width": "1",
                fill: "none",
                "fill-rule": "evenodd",
              },
            },
            [
              _c(
                "g",
                { attrs: { transform: "translate(0.000000, -1.000000)" } },
                [
                  _c(
                    "mask",
                    { attrs: { id: "shadow-mask-2", fill: "white" } },
                    [_c("use", { attrs: { "xlink:href": "#shadow-path-1" } })]
                  ),
                  _vm._v(" "),
                  _c("g", { attrs: { id: "Mask" } }),
                  _vm._v(" "),
                  _c(
                    "g",
                    { attrs: { "fill-rule": "nonzero", mask: "url(#mask-2)" } },
                    [
                      _c("use", {
                        attrs: {
                          fill: "black",
                          "fill-opacity": "1",
                          filter: "url(#shadow-filter-4)",
                          "xlink:href": "#shadow-path-3",
                        },
                      }),
                      _vm._v(" "),
                      _c("rect", {
                        attrs: {
                          "stroke-width": "1",
                          "stroke-linejoin": "square",
                          fill: "transparent",
                          "fill-rule": "evenodd",
                          x: "-12.5",
                          y: "20.5",
                          width: "431",
                          height: "82",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "apos-modal-lip__content" },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }