var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    class: _vm.getClasses(),
    domProps: { innerHTML: _vm._s(_vm.rendered) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }