var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "label",
      {
        staticClass: "apos-input-wrapper apos-file-dropzone",
        class: {
          "apos-file-dropzone--dragover": _vm.dragging,
          "apos-is-disabled": _vm.disabled || _vm.fileOrAttachment,
        },
        on: {
          drop: function ($event) {
            $event.preventDefault()
            return _vm.uploadFile.apply(null, arguments)
          },
          dragover: _vm.dragHandler,
          dragleave: function ($event) {
            _vm.dragging = false
          },
        },
      },
      [
        _c(
          "p",
          { staticClass: "apos-file-instructions" },
          [
            _vm.dragging
              ? [_c("cloud-upload-icon", { attrs: { size: 38 } })]
              : _vm.uploading
              ? _c("AposSpinner")
              : [
                  _c("paperclip-icon", {
                    staticClass: "apos-file-icon",
                    attrs: { size: 14 },
                  }),
                  _vm._v(
                    "\n        " + _vm._s(_vm.messages.primary) + " \n        "
                  ),
                  _vm.messages.highlighted
                    ? _c("span", { staticClass: "apos-file-highlight" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.messages.highlighted) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ],
          ],
          2
        ),
        _vm._v(" "),
        _c("input", {
          ref: "uploadField",
          staticClass: "apos-sr-only",
          attrs: {
            type: "file",
            disabled: _vm.disabled || _vm.fileOrAttachment,
            accept: _vm.allowedExtensions,
          },
          on: { input: _vm.uploadFile },
        }),
      ]
    ),
    _vm._v(" "),
    _vm.fileOrAttachment
      ? _c(
          "div",
          { staticClass: "apos-file-files" },
          [
            _c("AposSlatList", {
              attrs: {
                value: [_vm.fileOrAttachment],
                disabled: _vm.attachmentDisabled,
              },
              on: { input: _vm.update },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }