var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AposModal", {
    staticClass: "apos-doc-editor",
    attrs: { modal: _vm.modal, "modal-title": _vm.modalTitle },
    on: {
      inactive: function ($event) {
        _vm.modal.active = false
      },
      "show-modal": function ($event) {
        _vm.modal.showModal = true
      },
      esc: _vm.confirmAndCancel,
      "no-modal": function ($event) {
        return _vm.$emit("safe-close")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "secondaryControls",
        fn: function () {
          return [
            _c("AposButton", {
              attrs: { type: "default", label: "apostrophe:cancel" },
              on: { click: _vm.confirmAndCancel },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "primaryControls",
        fn: function () {
          return [
            _vm.original
              ? _c("AposDocContextMenu", {
                  attrs: {
                    disabled: _vm.errorCount > 0 || _vm.restoreOnly,
                    doc: _vm.original,
                    current: _vm.docFields.data,
                    published: _vm.published,
                    "show-edit": false,
                    "can-delete-draft": _vm.moduleOptions.canDeleteDraft,
                  },
                  on: { close: _vm.close },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.restoreOnly
              ? _c("AposButton", {
                  attrs: {
                    type: "primary",
                    label: _vm.saveLabel,
                    disabled: _vm.saveDisabled,
                    tooltip: _vm.errorTooltip,
                  },
                  on: { click: _vm.onRestore },
                })
              : _vm.saveMenu
              ? _c("AposButtonSplit", {
                  attrs: {
                    menu: _vm.saveMenu,
                    "menu-label": "Select Save Method",
                    disabled: _vm.saveDisabled,
                    tooltip: _vm.errorTooltip,
                    selected: _vm.savePreference,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.saveHandler($event)
                    },
                  },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "leftRail",
        fn: function () {
          return [
            _c(
              "AposModalRail",
              [
                _vm.tabs.length
                  ? _c("AposModalTabs", {
                      key: _vm.tabKey,
                      attrs: {
                        current: _vm.currentTab,
                        tabs: _vm.tabs,
                        errors: _vm.fieldErrors,
                      },
                      on: { "select-tab": _vm.switchPane },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "main",
        fn: function () {
          return [
            _c("AposModalBody", {
              scopedSlots: _vm._u([
                {
                  key: "bodyMain",
                  fn: function () {
                    return [
                      _vm.docReady
                        ? _c(
                            "div",
                            { staticClass: "apos-doc-editor__body" },
                            _vm._l(_vm.tabs, function (tab) {
                              return _c("AposSchema", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: tab.name === _vm.currentTab,
                                    expression: "tab.name === currentTab",
                                  },
                                ],
                                key: tab.name,
                                ref: tab.name,
                                refInFor: true,
                                attrs: {
                                  changed: _vm.changed,
                                  schema: _vm.groups[tab.name].schema,
                                  "current-fields": _vm.groups[tab.name].fields,
                                  "trigger-validation": _vm.triggerValidation,
                                  "utility-rail": false,
                                  "following-values":
                                    _vm.followingValues("other"),
                                  "conditional-fields": _vm.conditionalFields,
                                  "doc-id": _vm.docId,
                                  value: _vm.docFields,
                                  meta: _vm.docMeta,
                                  "server-errors": _vm.serverErrors,
                                  generation: _vm.generation,
                                },
                                on: {
                                  input: _vm.updateDocFields,
                                  validate: _vm.triggerValidate,
                                  "update-doc-data": _vm.onUpdateDocFields,
                                },
                              })
                            }),
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "rightRail",
        fn: function () {
          return [
            _c("AposModalRail", { attrs: { type: "right" } }, [
              _c(
                "div",
                { staticClass: "apos-doc-editor__utility" },
                [
                  _vm.docReady
                    ? _c("AposSchema", {
                        ref: "utilitySchema",
                        attrs: {
                          schema: _vm.groups["utility"].schema,
                          changed: _vm.changed,
                          "current-fields": _vm.groups["utility"].fields,
                          "trigger-validation": _vm.triggerValidation,
                          "utility-rail": true,
                          "following-values": _vm.followingUtils,
                          "conditional-fields": _vm.conditionalFields,
                          "doc-id": _vm.docId,
                          value: _vm.docFields,
                          meta: _vm.docMeta,
                          modifiers: ["small", "inverted"],
                          "server-errors": _vm.serverErrors,
                          generation: _vm.generation,
                        },
                        on: {
                          input: _vm.updateDocFields,
                          validate: _vm.triggerValidate,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }