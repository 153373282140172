var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "apos-area-menu__button",
      class: { "apos-area-menu__button--separated": _vm.item.type },
      attrs: { "data-action": _vm.item.name, tabindex: String(_vm.tabindex) },
      on: {
        click: _vm.click,
        keydown: [
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k(
                $event.keyCode,
                "arrow-down",
                undefined,
                $event.key,
                undefined
              )
            )
              return null
            $event.preventDefault()
            return _vm.$emit("down")
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k(
                $event.keyCode,
                "arrow-up",
                undefined,
                $event.key,
                undefined
              )
            )
              return null
            $event.preventDefault()
            return _vm.$emit("up")
          },
        ],
      },
    },
    [
      _vm.item.type === "clipboard"
        ? _c("div", { staticClass: "apos-area-menu__item-sublabel" }, [
            _vm._v("\n    Clipboard\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "apos-area-menu__item-content" },
        [
          _vm.item.icon
            ? _c(_vm.item.icon, {
                tag: "component",
                staticClass: "apos-area-menu__item-icon",
                attrs: { size: 15 },
              })
            : _vm._e(),
          _vm._v("\n    " + _vm._s(_vm.$t(_vm.item.label)) + "\n  "),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }