var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", { staticClass: "apos-tag-list__item" }, [
    _c(
      "button",
      {
        staticClass: "apos-tag-list__button",
        class: { "apos-is-active": _vm.active },
        on: {
          click: function ($event) {
            return _vm.click(_vm.tag)
          },
        },
      },
      [
        _c("AposIndicator", {
          staticClass: "apos-tag-list__icon",
          attrs: {
            icon: _vm.active ? "close-icon" : "label-icon",
            "fill-color": "var(--a-primary)",
            "icon-size": 12,
          },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "apos-tag-list__label" }, [
          _vm._v("\n      " + _vm._s(_vm.tag.label) + "\n    "),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }