var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "apos-field__wrapper",
      class: {
        [`apos-field__wrapper--${_vm.field.type}`]: true,
        "apos-field__wrapper--full-width": _vm.modifiers.includes("full-width"),
      },
    },
    [
      _c(
        _vm.wrapEl,
        { tag: "component", class: _vm.classList },
        [
          _c(
            "div",
            { staticClass: "apos-field__info" },
            [
              _vm.field.label
                ? _c(
                    _vm.labelEl,
                    {
                      tag: "component",
                      staticClass: "apos-field__label",
                      class: { "apos-sr-only": _vm.field.hideLabel },
                      attrs: {
                        for: _vm.uid,
                        "data-apos-test-name": _vm.field.name,
                        "data-apos-test-label": _vm.field.label,
                        "data-apos-test": "field-label",
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "apos-field_label-info" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t(_vm.label)) +
                              "\n          "
                          ),
                          _vm.field.required
                            ? _c(
                                "span",
                                { staticClass: "apos-field__required" },
                                [_vm._v("\n            *\n          ")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.field.tag
                            ? _c("AposLabel", {
                                staticClass: "apos-field__tag",
                                attrs: {
                                  label: _vm.field.tag.value || _vm.field.tag,
                                  modifiers: [
                                    `apos-is-${
                                      _vm.field.tag.type || "success"
                                    }`,
                                    "apos-is-filled",
                                  ],
                                  "data-apos-test": "field-tag",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          (_vm.field.help || _vm.field.htmlHelp) &&
                          _vm.displayOptions.helpTooltip
                            ? _c(
                                "span",
                                {
                                  staticClass: "apos-field__help-tooltip",
                                  attrs: {
                                    "data-apos-test": "field-help-tooltip",
                                  },
                                },
                                [
                                  _c("AposIndicator", {
                                    staticClass:
                                      "apos-field__help-tooltip__icon",
                                    attrs: {
                                      icon: "help-circle-icon",
                                      tooltip: _vm.$t(
                                        _vm.field.help || _vm.field.htmlHelp
                                      ),
                                      "icon-size": 11,
                                      "icon-color": "var(--a-base-4)",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.displayOptions.changed
                            ? _c(
                                "span",
                                {
                                  staticClass: "apos-field__changed",
                                  attrs: { "data-apos-test": "field-changed" },
                                },
                                [
                                  _c("AposLabel", {
                                    staticClass: "apos-field__changed__label",
                                    attrs: {
                                      label: "apostrophe:changed",
                                      modifiers: [
                                        "apos-is-warning",
                                        "apos-is-filled",
                                      ],
                                      tooltip:
                                        "apostrophe:fieldHasUnpublishedChanges",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "apos-field__label-meta",
                          attrs: { "data-apos-test": "field-meta-wrapper" },
                        },
                        _vm._l(_vm.metaComponents, function (component) {
                          return _c(component.name, {
                            key: component.name,
                            tag: "component",
                            attrs: {
                              field: _vm.field,
                              items: _vm.items,
                              namespace: component.namespace,
                              meta: component.data,
                              "meta-raw": _vm.meta,
                              "data-apos-test-component": component.name,
                              "data-apos-test-namespace": component.namespace,
                              "data-apos-test": "field-meta",
                            },
                          })
                        }),
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              (_vm.field.help || _vm.field.htmlHelp) &&
              !_vm.displayOptions.helpTooltip
                ? _c("p", {
                    staticClass: "apos-field__help",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t(_vm.field.help || _vm.field.htmlHelp)
                      ),
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._t("additional"),
            ],
            2
          ),
          _vm._v(" "),
          _vm._t("body"),
          _vm._v(" "),
          _vm.errorMessage
            ? _c(
                "div",
                {
                  staticClass: "apos-field__error",
                  attrs: { "data-apos-test": "field-error" },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t(_vm.errorMessage)) + "\n    "
                  ),
                ]
              )
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("secondary"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }