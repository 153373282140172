var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AposModal", {
    staticClass: "apos-settings",
    attrs: { modal: _vm.modal, "modal-title": _vm.$t("apostrophe:settings") },
    on: {
      esc: _vm.close,
      inactive: function ($event) {
        _vm.modal.active = false
      },
      "show-modal": function ($event) {
        _vm.modal.showModal = true
      },
      "no-modal": function ($event) {
        return _vm.$emit("safe-close")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "secondaryControls",
        fn: function () {
          return [
            _c("AposButton", {
              attrs: {
                type: "default",
                label: "apostrophe:close",
                disabled: _vm.busy,
              },
              on: { click: _vm.close },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "leftRail",
        fn: function () {
          return [
            _c("AposModalBody", {
              staticClass: "apos-settings__group",
              scopedSlots: _vm._u([
                {
                  key: "bodyMain",
                  fn: function () {
                    return [
                      _c(
                        "ul",
                        { staticClass: "apos-settings__group-items" },
                        _vm._l(_vm.groups, function (group, name) {
                          return _c(
                            "li",
                            {
                              key: name,
                              staticClass: "apos-settings__group-item",
                              class: {
                                "apos-is-active": name === _vm.activeGroup,
                              },
                            },
                            [
                              _c(
                                "button",
                                {
                                  attrs: {
                                    "data-apos-test": "groupTrigger",
                                    "data-apos-test-name": name,
                                    "data-apos-test-label": _vm.$t(group.label),
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.activeGroup = name
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t(group.label)) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "main",
        fn: function () {
          return [
            _vm.docReady
              ? _c("AposModalBody", {
                  staticClass: "apos-settings__content",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "bodyMain",
                        fn: function () {
                          return _vm._l(_vm.groups, function (group, name) {
                            return _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: name === _vm.activeGroup,
                                    expression: "name === activeGroup",
                                  },
                                ],
                                key: name,
                                staticClass: "apos-settings__subform-group",
                                attrs: {
                                  "data-apos-test": "subformGroup",
                                  "data-apos-test-name": name,
                                  "data-apos-test-label": _vm.$t(group.label),
                                  "data-apos-test-active":
                                    name === _vm.activeGroup,
                                },
                              },
                              [
                                _c(
                                  "h2",
                                  { staticClass: "apos-settings__heading" },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.$t(group.label)) +
                                        "\n          "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._l(group.subforms, function (subform) {
                                  return _c("AposSubform", {
                                    key: subform.name,
                                    ref: subform.name,
                                    refInFor: true,
                                    attrs: {
                                      busy: _vm.busy,
                                      separator: group.subforms.length > 1,
                                      errors: _vm.errors,
                                      subform: subform,
                                      values: _vm.values.data[subform.name],
                                      expanded: _vm.expanded === subform.name,
                                      "update-indicator":
                                        !!_vm.subformUpdateTimeouts[
                                          subform.name
                                        ],
                                      "data-apos-test": "subform",
                                      "data-apos-test-name": subform.name,
                                      "data-apos-test-label": _vm.$t(
                                        subform.label
                                      ),
                                      "data-apos-test-expanded": _vm.expanded
                                        ? "true"
                                        : "false",
                                    },
                                    on: {
                                      "update-expanded": _vm.updateExpanded,
                                      submit: _vm.submit,
                                    },
                                  })
                                }),
                              ],
                              2
                            )
                          })
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    479453379
                  ),
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }