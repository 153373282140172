var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", { staticClass: "apos-context-menu__item" }, [
    _c(
      "button",
      {
        staticClass: "apos-context-menu__button",
        class: _vm.modifiers,
        attrs: { tabindex: _vm.tabindex },
        on: { click: _vm.click },
      },
      [_vm._v("\n    " + _vm._s(_vm.$t(_vm.label)) + "\n  ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }