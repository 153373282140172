var render = function render(_c, _vm) {
  return _c(
    "span",
    _vm._g(
      _vm._b(
        {
          staticClass: "material-design-icon magnify-icon",
          class: [_vm.data.class, _vm.data.staticClass],
          attrs: {
            "aria-hidden": _vm.props.decorative,
            "aria-label": _vm.props.title,
            role: "img",
          },
        },
        "span",
        _vm.data.attrs,
        false
      ),
      _vm.listeners
    ),
    [
      _c(
        "svg",
        {
          staticClass: "material-design-icon__svg",
          attrs: {
            fill: _vm.props.fillColor,
            width: _vm.props.size,
            height: _vm.props.size,
            viewBox: "0 0 24 24",
          },
        },
        [
          _c(
            "path",
            {
              attrs: {
                d: "M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z",
              },
            },
            [
              !_vm.props.decorative
                ? _c("title", [_vm._v(_vm._s(_vm.props.title))])
                : _vm._e(),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }