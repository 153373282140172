var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { key: "status", staticClass: "apos-admin-bar__status" }, [
    _c(
      "span",
      { staticClass: "apos-admin-bar__status__inner" },
      [
        _c(
          _vm.savingIndicator.componentName,
          _vm._b(
            { tag: "component", staticClass: "apos-admin-bar__status__icon" },
            "component",
            _vm.savingIndicator.options,
            false
          )
        ),
        _vm._v(" "),
        _c(
          "div",
          { ref: "statusLabel", staticClass: "apos-admin-bar__status__label" },
          [_vm._v("\n      " + _vm._s(_vm.$t(_vm.savingLabel)) + "\n    ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }