var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "li",
      {
        staticClass: "apos-slat",
        class: {
          "apos-is-engaged": _vm.engaged,
          "apos-is-only-child": _vm.slatCount === 1,
          "apos-is-selected": _vm.selected,
          "apos-is-disabled": _vm.disabled,
        },
        attrs: {
          "data-id": _vm.item._id,
          tabindex: "0",
          "aria-pressed": _vm.engaged,
          role: "listitem",
          "aria-labelledby": _vm.parent,
        },
        on: {
          keydown: [
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "space", 32, $event.key, [
                  " ",
                  "Spacebar",
                ])
              )
                return null
              $event.preventDefault()
              return _vm.toggleEngage.apply(null, arguments)
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              $event.preventDefault()
              return _vm.toggleEngage.apply(null, arguments)
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k(
                  $event.keyCode,
                  "escape",
                  undefined,
                  $event.key,
                  undefined
                )
              )
                return null
              $event.preventDefault()
              return _vm.disengage.apply(null, arguments)
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k(
                  $event.keyCode,
                  "arrow-down",
                  undefined,
                  $event.key,
                  undefined
                )
              )
                return null
              $event.preventDefault()
              return _vm.move(1)
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k(
                  $event.keyCode,
                  "arrow-up",
                  undefined,
                  $event.key,
                  undefined
                )
              )
                return null
              $event.preventDefault()
              return _vm.move(-1)
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k(
                  $event.keyCode,
                  "backspace",
                  undefined,
                  $event.key,
                  undefined
                )
              )
                return null
              $event.preventDefault()
              return _vm.remove(true)
            },
          ],
          click: _vm.click,
        },
      },
      [
        _c(
          "div",
          { staticClass: "apos-slat__main" },
          [
            _vm.slatCount > 1
              ? _c("drag-icon", {
                  staticClass: "apos-slat__control apos-slat__control--drag",
                  attrs: { size: 13 },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.hasRelationshipFields && _vm.more.menu.length
              ? _c("AposContextMenu", {
                  attrs: {
                    button: _vm.more.button,
                    menu: _vm.more.menu,
                    "menu-placement": "bottom-start",
                    "menu-offset": "40, 10",
                    disabled: _vm.disabled,
                  },
                  on: {
                    "item-clicked": function ($event) {
                      return _vm.$emit("item-clicked", _vm.item)
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.editorIcon && _vm.hasRelationshipEditor
              ? _c("AposButton", {
                  staticClass: "apos-slat__editor-btn",
                  attrs: {
                    role: "button",
                    tooltip: {
                      content: _vm.editorLabel,
                      placement: "bottom",
                    },
                    icon: _vm.editorIcon,
                    "icon-only": true,
                    modifiers: ["inline"],
                    disabled: _vm.disabled,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("item-clicked", _vm.item)
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.item._url || _vm.item._urls
              ? _c(
                  "a",
                  {
                    staticClass: "apos-slat__control apos-slat__control--view",
                    attrs: {
                      href: _vm.item._url || _vm.item._urls.original,
                      target: "_blank",
                    },
                  },
                  [
                    _c("eye-icon", {
                      staticClass: "apos-slat__control--view-icon",
                      attrs: { size: 14 },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.item.attachment &&
            _vm.item.attachment.group === "images" &&
            _vm.item.attachment._urls
              ? _c("div", { staticClass: "apos-slat__media-preview" }, [
                  _c("img", {
                    staticClass: "apos-slat__media",
                    attrs: {
                      src: _vm.item.attachment._urls.uncropped
                        ? _vm.item.attachment._urls.uncropped["one-sixth"]
                        : _vm.item.attachment._urls["one-sixth"],
                      alt: _vm.item.description || _vm.item.title,
                    },
                  }),
                ])
              : _vm.item.extension
              ? _c("div", { staticClass: "apos-slat__extension-wrapper" }, [
                  _c(
                    "span",
                    {
                      staticClass: "apos-slat__extension",
                      class: [`apos-slat__extension--${_vm.item.extension}`],
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.item.extension) +
                          "\n        "
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "apos-slat__label" }, [
              _vm._v("\n        " + _vm._s(_vm.item.title) + "\n      "),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "apos-slat__secondary" },
          [
            _vm.item.length && _vm.item.length.size
              ? _c("div", { staticClass: "apos-slat__size" }, [
                  _vm._v("\n        " + _vm._s(_vm.itemSize) + "\n      "),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.removable
              ? _c("AposButton", {
                  staticClass: "apos-slat__control apos-slat__control--remove",
                  attrs: {
                    icon: "close-icon",
                    "icon-only": true,
                    modifiers: ["inline"],
                    label: "apostrophe:removeItem",
                    disabled: _vm.disabled,
                  },
                  on: { click: _vm.remove },
                })
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }