"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.booleanProp = void 0;
const util_1 = require("../util");
/**
 * Allows any boolean (validated at runtime and compile time).
 *
 * @param validator - Optional function for further runtime validation; should return `undefined` if valid, or an error string if invalid.
 */
const booleanProp = (validator) => (0, util_1.propOptionsGenerator)(Boolean, validator);
exports.booleanProp = booleanProp;
