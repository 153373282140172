var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "apos-tag" }, [
    _c(
      "button",
      { staticClass: "apos-tag__button", on: { click: _vm.click } },
      [
        _c("close-icon", {
          staticClass: "apos-tag__remove",
          attrs: { title: "Remove Tag", size: 10 },
        }),
      ],
      1
    ),
    _vm._v("\n  " + _vm._s(_vm.label) + "\n"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }