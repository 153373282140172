var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      directives: [
        {
          name: "apos-tooltip",
          rawName: "v-apos-tooltip",
          value: _vm.tooltip,
          expression: "tooltip",
        },
      ],
      staticClass: "apos-label",
      class: _vm.modifiers,
    },
    [_vm._v("\n  " + _vm._s(_vm.$t(_vm.label)) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }