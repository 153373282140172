var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "apos-subform-preview" }, [
    _c("div", { staticClass: "apos-subform-preview__label" }, [
      _vm._v(
        "\n    " +
          _vm._s(
            _vm.$t(_vm.subform.label || _vm.subform.schema?.[0].label || "n/a")
          ) +
          "\n  "
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "apos-subform-preview__value",
        class: { "is-help": !!_vm.subform.help },
      },
      [
        _vm.subform.previewComponent
          ? _c(_vm.subform.previewComponent, {
              tag: "component",
              staticClass: "apos-subform-preview__value-block",
              attrs: { subform: _vm.subform, values: _vm.values },
            })
          : _c("span", { staticClass: "apos-subform-preview__value-block" }, [
              _vm._v("\n      " + _vm._s(_vm.previewValue) + "\n    "),
            ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }