var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "apos-input__role__permission-grid" },
    _vm._l(_vm.permissionSets, function (permissionSet) {
      return _c(
        "div",
        {
          key: permissionSet.name,
          staticClass: "apos-input__role__permission-grid__set",
        },
        [
          _c(
            "h4",
            { staticClass: "apos-input__role__permission-grid__set-name" },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t(permissionSet.label)) + "\n      "
              ),
              permissionSet.includes
                ? _c("AposIndicator", {
                    staticClass: "apos-input__role__permission-grid__help",
                    attrs: {
                      icon: "help-circle-icon",
                      tooltip: _vm.getTooltip(permissionSet.includes),
                      "icon-size": 11,
                      "icon-color": "var(--a-base-4)",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "dl",
            { staticClass: "apos-input__role__permission-grid__list" },
            _vm._l(permissionSet.permissions, function (permission) {
              return _c(
                "div",
                {
                  key: permission.name,
                  staticClass: "apos-input__role__permission-grid__row",
                },
                [
                  _c(
                    "dd",
                    { staticClass: "apos-input__role__permission-grid__value" },
                    [
                      _c("AposIndicator", {
                        attrs: {
                          icon: permission.value
                            ? "check-bold-icon"
                            : "close-icon",
                          "icon-color": permission.value
                            ? "var(--a-success)"
                            : "var(--a-base-5)",
                        },
                      }),
                      _vm._v(" "),
                      permission.value
                        ? _c("span", { staticClass: "apos-sr-only" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("apostrophe:enabled")) +
                                "\n          "
                            ),
                          ])
                        : _c("span", { staticClass: "apos-sr-only" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("apostrophe:disabled")) +
                                "\n          "
                            ),
                          ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "dt",
                    { staticClass: "apos-input__role__permission-grid__label" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t(permission.label)) +
                          "\n        "
                      ),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }