var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.classes },
    [
      _vm.contextBarActive
        ? [
            _c("TheAposContextUndoRedo", {
              attrs: {
                "v-if": _vm.editMode,
                "can-undo": _vm.canUndo,
                "can-redo": _vm.canRedo,
                retrying: _vm.retrying,
                editing: _vm.editing,
                saving: _vm.saving,
                saved: _vm.saved,
              },
              on: { undo: _vm.undo, redo: _vm.redo },
            }),
            _vm._v(" "),
            !_vm.hasCustomUi
              ? _c("TheAposContextTitle", {
                  attrs: { context: _vm.context, "draft-mode": _vm.draftMode },
                  on: { "switch-draft-mode": _vm.switchDraftMode },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("TheAposContextModeAndSettings", {
              attrs: {
                context: _vm.context,
                published: _vm.published,
                "edit-mode": _vm.editMode,
                "has-custom-ui": _vm.hasCustomUi,
                "can-publish": _vm.canPublish,
                "ready-to-publish": _vm.readyToPublish,
                "custom-publish-label": _vm.customPublishLabel,
              },
              on: {
                "switch-edit-mode": _vm.switchEditMode,
                publish: _vm.onPublish,
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }