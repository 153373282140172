var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AposInputWrapper", {
    attrs: {
      field: _vm.field,
      error: _vm.effectiveError,
      uid: _vm.uid,
      modifiers: _vm.modifiers,
      "display-options": _vm.displayOptions,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _vm.field.style === "combo" && _vm.choices.length
              ? _c("AposCombo", {
                  attrs: {
                    choices: _vm.choices,
                    field: _vm.field,
                    value: _vm.value,
                  },
                  on: { "select-items": _vm.selectItems },
                })
              : _vm._l(_vm.choices, function (choice) {
                  return _c("AposCheckbox", {
                    key: choice.value,
                    attrs: {
                      for: _vm.getChoiceId(_vm.uid, choice.value),
                      id: _vm.getChoiceId(_vm.uid, choice.value),
                      choice: choice,
                      field: _vm.field,
                    },
                    model: {
                      value: _vm.value.data,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "data", $$v)
                      },
                      expression: "value.data",
                    },
                  })
                }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }