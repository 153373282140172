var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AposContextMenu",
    {
      attrs: { "menu-placement": "bottom-end" },
      on: {
        open: function ($event) {
          _vm.open = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "apos-apply-tag-menu__inner" },
        [
          _c("AposInputString", {
            ref: "textInput",
            attrs: {
              field: _vm.searchField,
              value: _vm.searchValue,
              status: _vm.searchStatus,
            },
            on: { input: _vm.updateSearchInput, return: _vm.create },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "apos-apply-tag__create" },
            [
              _c("AposButton", {
                attrs: {
                  label: _vm.createLabel,
                  type: "quiet",
                  disabled: _vm.disabledCreate,
                  "disable-focus": !_vm.open,
                },
                on: { click: _vm.create },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.searchTags.length && !_vm.creating
              ? _c(
                  "ol",
                  { staticClass: "apos-apply-tag-menu__tags" },
                  _vm._l(_vm.searchTags, function (tag) {
                    return _c(
                      "li",
                      {
                        key: `${_vm.keyPrefix}-${tag.slug}`,
                        staticClass: "apos-apply-tag-menu__tag",
                      },
                      [
                        _vm.checkboxes[tag.slug]
                          ? _c("AposCheckbox", {
                              attrs: {
                                field: _vm.checkboxes[tag.slug].field,
                                status: _vm.checkboxes[tag.slug].status,
                                choice: _vm.checkboxes[tag.slug].choice,
                                "disable-focus": !_vm.open,
                              },
                              on: { updated: _vm.updateTag },
                              model: {
                                value: _vm.checked,
                                callback: function ($$v) {
                                  _vm.checked = $$v
                                },
                                expression: "checked",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.searchTags.length && _vm.myTags.length && !_vm.creating
              ? _c("div", { staticClass: "apos-apply-tag-menu__empty" }, [
                  _c(
                    "p",
                    { staticClass: "apos-apply-tag-menu__empty-message" },
                    [
                      _vm._v(
                        "\n          We couldn't find any matching tags. Perhaps\n          "
                      ),
                      _c("AposButton", {
                        attrs: {
                          label: `create ${_vm.searchInputValue} ?`,
                          type: "quiet",
                          disabled: _vm.disabledCreate,
                          "disable-focus": !_vm.open,
                        },
                        on: { click: _vm.create },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "apos-apply-tag-menu__empty-icon" },
                    [_vm._v("\n          🌾\n        ")]
                  ),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }