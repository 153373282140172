var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AposInputWrapper", {
    attrs: {
      modifiers: _vm.modifiers,
      field: _vm.field,
      error: _vm.effectiveError,
      uid: _vm.uid,
      "display-options": _vm.displayOptions,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { class: _vm.classList }, [
              _c("input", {
                ref: "true",
                staticClass:
                  "apos-sr-only apos-boolean__input apos-boolean__input--true",
                attrs: {
                  type: "radio",
                  id: `${_vm.uid}-true`,
                  disabled: _vm.field.readOnly,
                },
                domProps: { value: true, checked: _vm.value.data === true },
                on: {
                  change: function ($event) {
                    return _vm.setValue(true)
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "apos-boolean__label apos-input",
                  attrs: { for: `${_vm.uid}-true` },
                },
                [
                  _c("circle-icon", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.field.toggle,
                        expression: "!field.toggle",
                      },
                    ],
                    staticClass: "apos-boolean__icon",
                    attrs: { size: 12, title: "" },
                  }),
                  _vm._v(
                    "\n        " + _vm._s(_vm.trueLabel || "Yes") + "\n      "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("input", {
                ref: "false",
                staticClass:
                  "apos-sr-only apos-boolean__input apos-boolean__input--false",
                attrs: {
                  type: "radio",
                  id: `${_vm.uid}-false`,
                  disabled: _vm.field.readOnly,
                },
                domProps: { value: false, checked: _vm.value.data === false },
                on: {
                  change: function ($event) {
                    return _vm.setValue(false)
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "apos-boolean__label apos-input",
                  attrs: { for: `${_vm.uid}-false` },
                },
                [
                  _c("circle-icon", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.field.toggle,
                        expression: "!field.toggle",
                      },
                    ],
                    staticClass: "apos-boolean__icon",
                    attrs: { size: 12, title: "" },
                  }),
                  _vm._v(
                    "\n        " + _vm._s(_vm.falseLabel || "No") + "\n      "
                  ),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }