var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", { staticClass: "apos-table" }, [
    _c(
      "tbody",
      [
        _c(
          "tr",
          [
            _c("th", { staticClass: "apos-table__header" }),
            _vm._v(" "),
            _vm._l(_vm.headers, function (header) {
              return _c(
                "th",
                {
                  key: header.label,
                  staticClass: "apos-table__header",
                  class: `apos-table__header--${header.name}`,
                  attrs: { scope: "col" },
                },
                [
                  _c(
                    _vm.getEl(header),
                    {
                      tag: "component",
                      staticClass: "apos-table__header-label",
                    },
                    [
                      header.labelIcon
                        ? _c(_vm.icons[header.labelIcon], {
                            tag: "component",
                            staticClass: "apos-table__header-icon",
                            attrs: { size: _vm.iconSize(header) },
                          })
                        : _vm._e(),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t(header.label)) +
                          "\n        "
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            _vm._v(" "),
            _c(
              "th",
              { key: "contextMenu", staticClass: "apos-table__header" },
              [
                _c(
                  _vm.getEl({}),
                  {
                    tag: "component",
                    staticClass: "apos-table__header-label apos-is-hidden",
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("apostrophe:moreOperations")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          2
        ),
        _vm._v(" "),
        _vm._l(_vm.items, function (item) {
          return _c(
            "tr",
            {
              key: item._id,
              staticClass: "apos-table__row",
              class: { "apos-is-selected": false },
              on: {
                mouseover: function ($event) {
                  return _vm.over(item._id)
                },
                mouseout: function ($event) {
                  return _vm.out(item._id)
                },
              },
            },
            [
              _c(
                "td",
                { staticClass: "apos-table__cell" },
                [
                  item._id
                    ? _c("AposCheckbox", {
                        directives: [
                          {
                            name: "apos-tooltip",
                            rawName: "v-apos-tooltip",
                            value:
                              _vm.options.disableUnpublished &&
                              !item.lastPublishedAt
                                ? "apostrophe:publishBeforeUsingTooltip"
                                : null,
                            expression:
                              "options.disableUnpublished && !item.lastPublishedAt ? 'apostrophe:publishBeforeUsingTooltip' : null",
                          },
                        ],
                        attrs: {
                          field: {
                            name: item._id,
                            hideLabel: true,
                            label: `Toggle selection of ${item.title}`,
                            readOnly:
                              (_vm.options.disableUnchecked &&
                                !_vm.checkProxy.includes(item._id)) ||
                              (_vm.options.disableUnpublished &&
                                !item.lastPublishedAt),
                          },
                          choice: { value: item._id },
                        },
                        on: {
                          updated: function ($event) {
                            return _vm.emitUpdated(item._id)
                          },
                        },
                        model: {
                          value: _vm.checkProxy,
                          callback: function ($$v) {
                            _vm.checkProxy = $$v
                          },
                          expression: "checkProxy",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.headers, function (header) {
                return _c(
                  "td",
                  {
                    key: header.name,
                    staticClass: "apos-table__cell apos-table__cell--pointer",
                    class: `apos-table__cell--${header.name}`,
                    on: {
                      click: function ($event) {
                        _vm.canEdit(item) && _vm.$emit("open", item)
                      },
                    },
                  },
                  [
                    header.component
                      ? _c(header.component, {
                          tag: "component",
                          attrs: {
                            header: header,
                            item: item._publishedDoc || item,
                            draft: item,
                            published: item._publishedDoc,
                            "manually-published": _vm.options.manuallyPublished,
                          },
                        })
                      : _c("AposCellBasic", {
                          attrs: {
                            header: header,
                            draft: item,
                            published: item._publishedDoc,
                          },
                        }),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "td",
                {
                  staticClass:
                    "apos-table__cell apos-table__cell--context-menu",
                },
                [
                  _c("AposCellContextMenu", {
                    attrs: {
                      state: _vm.state[item._id],
                      item: item,
                      draft: item,
                      published: item._publishedDoc,
                      header: {
                        columnHeader: "",
                        property: "contextMenu",
                        component: "AposCellContextMenu",
                      },
                      options: _vm.contextMenuOptions,
                    },
                  }),
                ],
                1
              ),
            ],
            2
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }