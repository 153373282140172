var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "apos-primary-scrollbar apos-search" },
    _vm._l(_vm.list, function (item) {
      return _c(
        "li",
        {
          directives: [
            {
              name: "apos-tooltip",
              rawName: "v-apos-tooltip",
              value: _vm.getTooltip(item),
              expression: "getTooltip(item)",
            },
          ],
          key: item._id,
          class: _vm.getClasses(item),
          on: {
            click: function ($event) {
              return _vm.select(item, $event)
            },
          },
        },
        [
          item?.attachment?._urls?.["one-sixth"]
            ? _c("img", {
                staticClass: "apos-search-image",
                attrs: { src: item.attachment._urls["one-sixth"] },
              })
            : _vm.getIcon(item).icon
            ? _c("AposIndicator", {
                staticClass: "apos-button__icon",
                attrs: {
                  icon: _vm.getIcon(item).icon,
                  "icon-size": _vm.getIcon(item).iconSize,
                  "fill-color": "currentColor",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "apos-search__item__title" }, [
            _vm._v("\n      " + _vm._s(item.title) + "\n    "),
          ]),
          _vm._v(" "),
          _vm._l(_vm.fields, function (field) {
            return _c(
              "div",
              { key: field, staticClass: "apos-search__item__field" },
              [_vm._v("\n      " + _vm._s(item[field]) + "\n    ")]
            )
          }),
          _vm._v(" "),
          _vm._l(item.customFields, function (field) {
            return _c(
              "div",
              { key: field, staticClass: "apos-search__item__field" },
              [_vm._v("\n      " + _vm._s(item[field]) + "\n    ")]
            )
          }),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }