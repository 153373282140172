"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.oneOfTypesProp = void 0;
const util_1 = require("../util");
/**
 * Allows any of the passed constructor types (validated at runtime).
 *
 * Type parameter `T` has to be used to adjust the type at compile time.
 *
 * @param type - A single constructor or an array of constructors to allow.
 * @param validator - Optional function for further runtime validation; should return `undefined` if valid, or an error string if invalid.
 */
const oneOfTypesProp = (type, validator) => (0, util_1.propOptionsGenerator)(type, validator);
exports.oneOfTypesProp = oneOfTypesProp;
