var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "apos-empty-state" }, [
    _vm.emptyState.title
      ? _c("p", { staticClass: "apos-title" }, [
          _vm._v("\n    " + _vm._s(_vm.$t(_vm.emptyState.title)) + "\n  "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.emptyState.message
      ? _c("p", { staticClass: "apos-hint" }, [
          _vm._v("\n    " + _vm._s(_vm.$t(_vm.emptyState.message)) + "\n  "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.emptyState.emoji
      ? _c("div", { staticClass: "apos-emoji" }, [
          _vm._v("\n    " + _vm._s(_vm.emptyState.emoji) + "\n  "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }