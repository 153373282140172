var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "apos-rich-text-editor__control apos-rich-text-editor__control--divider",
    },
    [_vm._v("\n  |\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }