var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      attrs: { "aria-controls": `insert-menu-${_vm.value._id}` },
      on: { keydown: _vm.handleUIKeydown },
    },
    [
      _vm.editor
        ? _c(
            "bubble-menu",
            {
              staticClass: "bubble-menu",
              attrs: {
                "tippy-options": {
                  maxWidth: "none",
                  duration: 300,
                  zIndex: 2000,
                  animation: "fade",
                  inertia: true,
                },
                editor: _vm.editor,
              },
            },
            [
              _c(
                "AposContextMenuDialog",
                {
                  attrs: {
                    "menu-placement": "top",
                    "class-list": "apos-rich-text-toolbar",
                    "has-tip": false,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "apos-rich-text-toolbar__inner" },
                    _vm._l(_vm.toolbar, function (item, index) {
                      return _c(
                        (_vm.tools[item] && _vm.tools[item].component) ||
                          "AposTiptapUndefined",
                        {
                          key: item + "-" + index,
                          tag: "component",
                          attrs: {
                            name: item,
                            tool: _vm.tools[item],
                            options: _vm.editorOptions,
                            editor: _vm.editor,
                          },
                        }
                      )
                    }),
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.editor
        ? _c(
            "floating-menu",
            {
              key: _vm.insertMenuKey,
              ref: "insertMenu",
              staticClass: "apos-rich-text-insert-menu",
              attrs: {
                "tippy-options": {
                  duration: 100,
                  zIndex: 2000,
                  placement: "bottom-start",
                },
                "should-show": _vm.showFloatingMenu,
                editor: _vm.editor,
                role: "listbox",
                tabindex: "0",
                id: `insert-menu-${_vm.value._id}`,
              },
            },
            [
              _c("div", { staticClass: "apos-rich-text-insert-menu-heading" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("apostrophe:richTextInsertMenuHeading")) +
                    "\n    "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "apos-rich-text-insert-menu-wrapper",
                  on: {
                    keydown: [
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "arrow-up",
                            undefined,
                            $event.key,
                            undefined
                          )
                        )
                          return null
                        $event.preventDefault()
                        return _vm.focusInsertMenuItem(true)
                      },
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "arrow-down",
                            undefined,
                            $event.key,
                            undefined
                          )
                        )
                          return null
                        $event.preventDefault()
                        return _vm.focusInsertMenuItem()
                      },
                      _vm.closeInsertMenu,
                    ],
                  },
                },
                [
                  _vm._l(_vm.insert, function (item, index) {
                    return _c(
                      "button",
                      {
                        key: `${item}-${index}`,
                        staticClass: "apos-rich-text-insert-menu-item",
                        attrs: { role: "option", "data-insert-menu-item": "" },
                        on: {
                          click: function ($event) {
                            return _vm.activateInsertMenuItem(
                              item,
                              _vm.insertMenu[item]
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "apos-rich-text-insert-menu-icon" },
                          [
                            _c("AposIndicator", {
                              staticClass: "apos-button__icon",
                              attrs: {
                                icon: _vm.insertMenu[item].icon,
                                "icon-size": 24,
                                "fill-color": "currentColor",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "apos-rich-text-insert-menu-label" },
                          [
                            _c("h4", [
                              _vm._v(
                                _vm._s(_vm.$t(_vm.insertMenu[item].label))
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t(_vm.insertMenu[item].description))
                              ),
                            ]),
                          ]
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "apos-rich-text-insert-menu-components" },
                    _vm._l(_vm.insert, function (item, index) {
                      return _c(
                        "div",
                        { key: `${item}-${index}-component` },
                        [
                          item === _vm.activeInsertMenuComponent?.name
                            ? _c(_vm.activeInsertMenuComponent.component, {
                                tag: "component",
                                attrs: {
                                  active: true,
                                  editor: _vm.editor,
                                  options: _vm.editorOptions,
                                },
                                on: {
                                  "before-commands": _vm.removeSlash,
                                  cancel: _vm.cancelInsertMenuItem,
                                  done: _vm.closeInsertMenuItem,
                                  close: _vm.closeInsertMenuItem,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "apos-rich-text-editor__editor",
          class: _vm.editorModifiers,
        },
        [
          _c("editor-content", {
            class: _vm.editorOptions.className,
            attrs: { editor: _vm.editor },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showPlaceholder !== null && (!_vm.placeholderText || !_vm.isFocused)
        ? _c(
            "div",
            {
              staticClass: "apos-rich-text-editor__editor_after",
              class: _vm.editorModifiers,
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(_vm.$t("apostrophe:emptyRichTextWidget")) +
                  "\n  "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }