var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AposModal",
    _vm._g(
      {
        staticClass: "apos-share-draft",
        attrs: { modal: _vm.modal, "data-apos-test": "share-draft-modal" },
        on: {
          "no-modal": function ($event) {
            return _vm.$emit("safe-close")
          },
          inactive: function ($event) {
            _vm.modal.active = false
          },
          "show-modal": function ($event) {
            _vm.modal.showModal = true
          },
        },
        scopedSlots: _vm._u([
          {
            key: "main",
            fn: function () {
              return [
                _c("AposModalBody", {
                  scopedSlots: _vm._u([
                    {
                      key: "bodyMain",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "apos-share-draft__header" },
                            [
                              _c(
                                "h2",
                                { staticClass: "apos-share-draft__heading" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t("apostrophe:shareDraftHeader")
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("Close", {
                                staticClass: "apos-share-draft__close",
                                attrs: {
                                  tabindex: "0",
                                  title: _vm.$t("apostrophe:close"),
                                  size: 18,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.close.apply(null, arguments)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "apos-share-draft__content" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "apos-share-draft__toggle-wrapper",
                                },
                                [
                                  _c("AposToggle", {
                                    staticClass: "apos-share-draft__toggle",
                                    on: { toggle: _vm.toggle },
                                    model: {
                                      value: _vm.disabled,
                                      callback: function ($$v) {
                                        _vm.disabled = $$v
                                      },
                                      expression: "disabled",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "apos-share-draft__toggle-label",
                                      on: { click: _vm.toggle },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t(
                                              "apostrophe:shareDraftEnable"
                                            )
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass: "apos-share-draft__description",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t(
                                          "apostrophe:shareDraftDescription"
                                        )
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "transition",
                                { attrs: { name: "collapse", duration: 200 } },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.disabled,
                                          expression: "!disabled",
                                        },
                                      ],
                                      staticClass:
                                        "apos-share-draft__url-block",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.shareUrl,
                                            expression: "shareUrl",
                                          },
                                        ],
                                        staticClass: "apos-share-draft__url",
                                        attrs: {
                                          type: "text",
                                          disabled: "",
                                          tabindex: "-1",
                                        },
                                        domProps: { value: _vm.shareUrl },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.shareUrl = $event.target.value
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "apos-share-draft__link-copy",
                                          attrs: { href: "" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.copy.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("LinkVariant", {
                                            staticClass:
                                              "apos-share-draft__link-icon",
                                            attrs: {
                                              title: _vm.$t(
                                                "apostrophe:shareDraftCopyLink"
                                              ),
                                              size: 16,
                                            },
                                          }),
                                          _vm._v(
                                            "\n                 " +
                                              _vm._s(
                                                _vm.$t(
                                                  "apostrophe:shareDraftCopyLink"
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
        ]),
      },
      { esc: _vm.close }
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }