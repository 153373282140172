"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfProp = void 0;
const util_1 = require("../util");
const validators_1 = require("../validators");
/**
 * Allows instances of the given constructor (validated at runtime and compile time).
 *
 * Type parameter `T` can be used to adjust the inferred type at compile time.
 *
 * @param parent - The constructor to allow.
 * @param validator - Optional function for further runtime validation; should return `undefined` if valid, or an error string if invalid.
 */
const instanceOfProp = (parent, validator) => (0, util_1.propOptionsGenerator)(parent, validator, (0, validators_1.isInstanceOf)(parent));
exports.instanceOfProp = instanceOfProp;
