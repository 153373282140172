var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "apos-link-control" },
    [
      _c("AposButton", {
        class: { "apos-is-active": _vm.buttonActive },
        attrs: {
          type: "rich-text",
          label: _vm.tool.label,
          "icon-only": !!_vm.tool.icon,
          icon: _vm.tool.icon || false,
          "icon-size": _vm.tool.iconSize || 16,
          modifiers: ["no-border", "no-motion"],
          tooltip: {
            content: _vm.tool.label,
            placement: "top",
            delay: 650,
          },
        },
        on: { click: _vm.click },
      }),
      _vm._v(" "),
      _vm.active
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "click-outside-element",
                  rawName: "v-click-outside-element",
                  value: _vm.close,
                  expression: "close",
                },
              ],
              staticClass: "apos-popover apos-link-control__dialog",
              class: {
                "apos-is-triggered": _vm.active,
                "apos-has-selection": _vm.hasSelection,
              },
              attrs: { "x-placement": "bottom" },
            },
            [
              _c(
                "AposContextMenuDialog",
                { attrs: { "menu-placement": "bottom-start" } },
                [
                  _vm.hasLinkOnOpen
                    ? _c(
                        "div",
                        { staticClass: "apos-link-control__remove" },
                        [
                          _c("AposButton", {
                            attrs: {
                              type: "quiet",
                              label: "apostrophe:removeLink",
                            },
                            on: { click: _vm.removeLink },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("AposSchema", {
                    key: _vm.lastSelectionTime,
                    attrs: {
                      schema: _vm.schema,
                      "trigger-validation": _vm.triggerValidation,
                      modifiers: _vm.formModifiers,
                      generation: _vm.generation,
                      "following-values": _vm.followingValues(),
                      "conditional-fields": _vm.conditionalFields,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.evaluateConditions()
                      },
                    },
                    model: {
                      value: _vm.docFields,
                      callback: function ($$v) {
                        _vm.docFields = $$v
                      },
                      expression: "docFields",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "footer",
                    { staticClass: "apos-link-control__footer" },
                    [
                      _c("AposButton", {
                        attrs: {
                          type: "default",
                          label: "apostrophe:cancel",
                          modifiers: _vm.formModifiers,
                        },
                        on: { click: _vm.close },
                      }),
                      _vm._v(" "),
                      _c("AposButton", {
                        attrs: {
                          type: "primary",
                          label: "apostrophe:save",
                          modifiers: _vm.formModifiers,
                          disabled: _vm.docFields.hasErrors,
                        },
                        on: { click: _vm.save },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }