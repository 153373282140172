"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stringProp = void 0;
const util_1 = require("../util");
/**
 * Allows any string. No further runtime validation is performed by default.
 *
 * Type parameter `T` can be used to restrict the type at compile time with a union type.
 *
 * @param validator - Optional function for further runtime validation; should return `undefined` if valid, or an error string if invalid.
 */
const stringProp = (validator) => (0, util_1.propOptionsGenerator)(String, validator);
exports.stringProp = stringProp;
