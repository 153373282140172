var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AposContextMenu",
    {
      ref: "menu",
      staticClass: "apos-admin-locales",
      attrs: {
        button: _vm.button,
        unpadded: true,
        "menu-placement": "bottom-end",
      },
      on: { open: _vm.open },
    },
    [
      _c("div", { staticClass: "apos-locales-picker" }, [
        _c("div", { staticClass: "apos-input-wrapper" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.search,
                expression: "search",
              },
            ],
            staticClass: "apos-locales-filter",
            attrs: {
              type: "text",
              placeholder: _vm.$t("apostrophe:searchLocalesPlaceholder"),
            },
            domProps: { value: _vm.search },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.search = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "apos-locales" },
          _vm._l(_vm.filteredLocales, function (locale) {
            return _c(
              "li",
              {
                key: locale.name,
                staticClass: "apos-locale-item",
                class: _vm.localeClasses(locale),
                on: {
                  click: function ($event) {
                    return _vm.switchLocale(locale)
                  },
                },
              },
              [
                _c(
                  "span",
                  { staticClass: "apos-locale" },
                  [
                    _vm.isActive(locale)
                      ? _c("AposIndicator", {
                          staticClass: "apos-check",
                          attrs: {
                            icon: "check-bold-icon",
                            "fill-color": "var(--a-primary)",
                            "icon-size": 12,
                            title: _vm.$t("apostrophe:currentLocale"),
                          },
                        })
                      : _vm._e(),
                    _vm._v(
                      "\n          " + _vm._s(locale.label) + "\n          "
                    ),
                    _c("span", { staticClass: "apos-locale-name" }, [
                      _vm._v(
                        "\n            (" +
                          _vm._s(locale.name) +
                          ")\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "apos-locale-localized",
                      class: {
                        "apos-state-is-localized": _vm.isLocalized(locale),
                      },
                    }),
                  ],
                  1
                ),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "apos-available-locales" },
          [
            _c("p", { staticClass: "apos-available-description" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("apostrophe:documentExistsInLocales")) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _vm._l(_vm.availableLocales, function (locale) {
              return _c("AposButton", {
                key: locale.name,
                staticClass: "apos-available-locale",
                attrs: {
                  label: locale.label,
                  type: "quiet",
                  modifiers: ["no-motion"],
                },
                on: {
                  click: function ($event) {
                    return _vm.switchLocale(locale)
                  },
                },
              })
            }),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }