"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.oneOfProp = void 0;
const util_1 = require("../util");
const validators_1 = require("../validators");
// inspired by https://github.com/dwightjack/vue-types/blob/4.1.1/src/validators/oneof.ts
const getOneOfType = (values) => {
    const allowedTypes = [
        ...new Set(values.flatMap((value) => {
            var _a;
            if (value === null || value === undefined) {
                return [];
            }
            return (
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            (_a = value.constructor) !== null && _a !== void 0 ? _a : []);
        })),
    ];
    if (allowedTypes.length === 0) {
        return undefined;
    }
    if (allowedTypes.length === 1) {
        return allowedTypes[0];
    }
    return allowedTypes;
};
/**
 * Allows any of the specified allowed values (validated at runtime and compile time).
 *
 * Type parameter `T` can be used to adjust the inferred type at compile time, this is usually not necessary.
 *
 * @param allowedValues - The allowed values.
 * @param validator - Optional function for further runtime validation; should return `undefined` if valid, or an error string if invalid.
 */
const oneOfProp = (allowedValues, validator) => (0, util_1.propOptionsGenerator)(getOneOfType(allowedValues), validator, (0, validators_1.isOneOf)(allowedValues));
exports.oneOfProp = oneOfProp;
