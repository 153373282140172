"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.functionProp = void 0;
const validators_1 = require("../validators");
/* eslint-disable @typescript-eslint/ban-types */
/**
 * Allows any function. No further runtime validation is performed by default.
 *
 * Type parameter `T` can be used to restrict the type to a specific function signature at compile time.
 *
 * @param validator - Optional function for further runtime validation; should return `undefined` if valid, or an error string if invalid.
 */
const functionProp = (validator) => ({
    optional: {
        type: Function,
        required: false,
        default: undefined,
        validator: (0, validators_1.vuePropValidator)(validator),
    },
    nullable: {
        type: Function,
        required: false,
        default: null,
        validator: (0, validators_1.vuePropValidator)(validator),
    },
    required: {
        type: Function,
        required: true,
        validator: (0, validators_1.vuePropValidator)(validator),
    },
});
exports.functionProp = functionProp;
