var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "p",
    {
      staticClass: "apos-table__cell-field",
      class: `apos-table__cell-field--${_vm.header.name}`,
    },
    [_vm._v("\n  " + _vm._s(_vm.get(_vm.header.name)) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }