var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "apos-admin-bar__items" },
    [
      _vm.pageTree
        ? _c(
            "li",
            { staticClass: "apos-admin-bar__item" },
            [
              _c("AposButton", {
                staticClass: "apos-admin-bar__btn",
                attrs: {
                  type: "subtle",
                  label: "apostrophe:pages",
                  modifiers: ["no-motion"],
                },
                on: {
                  click: function ($event) {
                    return _vm.emitEvent("@apostrophecms/page:manager")
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.menuItems, function (item) {
        return _c(
          "li",
          { key: item.name, staticClass: "apos-admin-bar__item" },
          [
            item.items
              ? _c("AposContextMenu", {
                  staticClass: "apos-admin-bar__sub",
                  attrs: {
                    menu: item.items,
                    button: {
                      label: item.label,
                      modifiers: ["no-motion"],
                      class: "apos-admin-bar__btn",
                      type: "subtle",
                    },
                  },
                  on: { "item-clicked": _vm.emitEvent },
                })
              : _c((item.options && item.options.component) || "AposButton", {
                  tag: "Component",
                  staticClass: "apos-admin-bar__btn",
                  attrs: {
                    type: "subtle",
                    label: item.label,
                    modifiers: ["no-motion"],
                  },
                  on: {
                    click: function ($event) {
                      return _vm.emitEvent(item.action)
                    },
                  },
                }),
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.createMenu.length > 0
        ? _c(
            "li",
            { staticClass: "apos-admin-bar__item" },
            [
              _c("AposContextMenu", {
                staticClass: "apos-admin-bar__create",
                attrs: {
                  menu: _vm.createMenu,
                  button: {
                    label: "apostrophe:newItem",
                    iconOnly: true,
                    icon: "plus-icon",
                    type: "primary",
                    modifiers: ["round", "no-motion"],
                  },
                },
                on: { "item-clicked": _vm.emitEvent },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.trayItems.length > 0
        ? _c(
            "li",
            { staticClass: "apos-admin-bar__item apos-admin-bar__tray-items" },
            [
              _vm._l(_vm.trayItems, function (item) {
                return [
                  item.options.component
                    ? _c(item.options.component, {
                        key: `${item.name}.component`,
                        tag: "Component",
                      })
                    : _c("AposButton", {
                        key: `${item.name}.fallback`,
                        staticClass: "apos-admin-bar__context-button",
                        attrs: {
                          type: "subtle",
                          modifiers: ["small", "no-motion"],
                          tooltip: _vm.trayItemTooltip(item),
                          icon: item.options.icon,
                          "icon-only": true,
                          label: item.label,
                          state: _vm.trayItemState[item.name] ? ["active"] : [],
                        },
                        on: {
                          click: function ($event) {
                            return _vm.emitEvent(item.action)
                          },
                        },
                      }),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }