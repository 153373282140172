"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.anyProp = void 0;
const util_1 = require("../util");
/**
 * Allows any type. No built-in runtime validation is performed by default.
 *
 * Type parameter `T` can be used to restrict the type at compile time.
 *
 * @param validator - Optional function for runtime validation; should return `undefined` if valid, or an error string if invalid.
 */
const anyProp = (validator) => (0, util_1.propOptionsGenerator)(undefined, validator);
exports.anyProp = anyProp;
