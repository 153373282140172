var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "apos-toggle__container" }, [
    _c("div", {
      staticClass: "apos-toggle__slider",
      class: { "apos-toggle__slider--activated": !_vm.value },
      on: {
        click: function ($event) {
          return _vm.$emit("toggle")
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }